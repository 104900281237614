import { Box, Button, Typography, useTheme } from "@mui/material";
import SearchBar from "components/common/SearchBar";
import Layout from "components/Dashboard/Layout";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBrokerList } from "redux/features/broker/brokerSlice";
import { setLicenseList } from "redux/features/license/licenseSlice";
import { setLicenseListLoader } from "redux/features/loader/loaderSlice";
import { getBrokerList } from "server/broker/broker";
import { getLicenseList } from "server/license/license";
import CreateLicenseModal from "./CreateLicenseModal";
import LicenseTable from "./LicenseTable";

const License = memo(() => {
  const [searchText, setSearchText] = useState("");
  const placeholderText = "Search by license key";
  const [createLicenseModalOpen, setCreateLicenseModalOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();

  const fetchLicenseList = async () => {
    dispatch(setLicenseListLoader(true));
    const result = await getLicenseList();
    if (result?.data?.status) {
      dispatch(setLicenseList(result?.data?.data));
      dispatch(setLicenseListLoader(false));
    } else {
      dispatch(setLicenseListLoader(false));
    }
  };
  const fetchBrokerList = async () => {
    const result = await getBrokerList();
    if (result?.data?.success) {
      dispatch(setBrokerList(result?.data?.data));
    }
  };

  const handleSearchTextChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  useEffect(() => {
    fetchBrokerList();
    fetchLicenseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Box>
        <Box className="flex items-center justify-between my-4">
          <Typography variant="h6" style={{ marginLeft: "15px", color: theme.palette.text.white }}>
            License
          </Typography>
          <Box sx={{ marginRight: "15px", display: "flex", alignItems: "center", gap: 2 }}>
            <Box>
              <SearchBar {...{ searchText, handleSearchTextChange, placeholderText }} />
            </Box>
            <Button
              onClick={() => setCreateLicenseModalOpen(true)}
              variant="contained"
              sx={{
                background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                color: "white",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
            >
              Create License
            </Button>
          </Box>
        </Box>
        <LicenseTable {...{ searchText }} />
      </Box>
      <CreateLicenseModal {...{ createLicenseModalOpen, setCreateLicenseModalOpen }} />
    </Layout>
  );
});

export default License;
