import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    backendUpdateList: [],
}

export const backendUpdateSlice = createSlice({
    name: 'backendUpdate',
    initialState,
    reducers: {
        setBackendUpdateList: (state, action) => {
            state.backendUpdateList = action.payload
        },
        addBackendUpdateList: (state, action) => {
            state.backendUpdateList.push(action.payload)
        },
        updateBackendUpdateList: (state, action) => {
            const index = state.backendUpdateList.findIndex(update => update._id === action.payload._id)
            if (index !== -1) {
                state.backendUpdateList[index] = {
                    ...state.backendUpdateList[index],
                    ...action.payload,
                }
            }
        },
        removeBackendUpdateList: (state, action) => {
            state.backendUpdateList = state.backendUpdateList.filter(update => update._id !== action.payload)
        },
    },
})

export const { setBackendUpdateList, removeBackendUpdateList, addBackendUpdateList, updateBackendUpdateList } = backendUpdateSlice.actions

export default backendUpdateSlice.reducer