import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { darkTheme, lightTheme } from 'theme';

const ThemeProviderWrapper = ({ children }) => {
  const mode = useSelector((state) => state.themeSlice.themeMode);
  const currentTheme = mode === 'dark' ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeProviderWrapper;
