import BackendForceUpdate from "components/BackendForceUpdate/BackendForceUpdate";
import BackendResult from "components/BackendResult/BackendResult";
import Broker from "components/Broker/Broker";
import ForceUpdate from "components/ForceUpdate/ForceUpdate";
import HelpCenter from "components/HelpCenter/HelpCenter";
import ExtendedLicense from "components/License/ExtendedLicense/ExtendedLicense";
import License from "components/License/License";
import UnUsedLicense from "components/License/UnUsedLicense/UnUsedLicense";
import Plan from "components/Plan/Plan";
import UploadFile from "components/UploadFile/UploadFile";
import UploadFileList from "components/UploadFile/UploadFileList";
import { SnackbarProvider } from "notistack";
import Dashboard from "pages/protected/Dashboard";
import React, { memo } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { store } from "redux/store";
import ThemeProviderWrapper from "theme/ThemeProviderWrapper";

const Auth = React.lazy(() => import("pages/auth/Auth"));
const About = React.lazy(() => import("pages/About"));
const NotFound = React.lazy(() => import("pages/NotFound"));
const AuthLayout = React.lazy(() => import("layouts/AuthLayout"));
const ProtectedLayout = React.lazy(() => import("layouts/ProtectedLayout"));

const App = () => {
  return (
    <Provider store={store}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        maxSnack={1}
        autoHideDuration={3000}
      >
        <ThemeProviderWrapper>
          <BrowserRouter>
            <Routes>
              <Route element={<AuthLayout />}>
                <Route path="/auth" element={<Auth />} />
              </Route>
              <Route element={<ProtectedLayout />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/broker" element={<Broker />} />
                <Route path="/license" element={<License />} />
                <Route path="/extended-license" element={<ExtendedLicense />} />
                <Route path="/unused-license" element={<UnUsedLicense />} />
                <Route path="/file-upload" element={<UploadFile />} />
                <Route path="/upload-file-list" element={<UploadFileList />} />
                <Route path="/plan" element={<Plan />} />
                <Route path="/force-update" element={<ForceUpdate />} />
                <Route path="/backend-force-update" element={<BackendForceUpdate />} />
                <Route path="/backend-result" element={<BackendResult />} />
                <Route path="/help-center" element={<HelpCenter />} />
              </Route>
              {/* Other route start here */}
              <Route path="/about" element={<About />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </ThemeProviderWrapper>
      </SnackbarProvider>
    </Provider>
  );
};

export default memo(App);
