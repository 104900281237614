import { CloudOutlined, ContentCopy, Delete } from "@mui/icons-material";
import { Avatar, Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Fragment, memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import DeleteUploadFileModal from "./DeleteUploadFileModal";
const UploadFileListTable = memo(() => {
  const { enqueueSnackbar } = useSnackbar();
  const [openUploadFileDelete, setOpenUploadFileDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const loading = useSelector((state) => state.loader.uploadFileLoader);
  const theme = useTheme();
  const uploadFileList = useSelector((state) => state.upload.uploadFileList);
  const [currentFile, setCurrentFile] = useState({});

  // Calculate the rows to display based on the current page and rows per page
  const displayedRows = uploadFileList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleCopy = useCallback((text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar("Image url copied to clipboard!", { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar("Failed to copy image url. Please try again.", { variant: "error" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      <Box>
        <TableContainer sx={{ height: "calc(100vh - 190px)", width: "100%" }} className="etrsc-scrollbar">
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ background: theme.palette.background.tableHeader }} className="shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]">
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Name
                </TableCell>

                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Upload Date
                </TableCell>

                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Image
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  File Size
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center" colSpan={7} sx={{ color: theme.palette.text.white }} className="!font-bold !text-base">
                    <CircularProgress size={28} color="success" />
                  </TableCell>
                </TableRow>
              ) : displayedRows?.length > 0 ? (
                displayedRows?.map((item, i) => (
                  <Fragment key={i}>
                    <TableRow
                      onClick={() => setCurrentFile(item)}
                      className={`shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]`}
                      sx={
                        {
                          // "&:last-child td, &:last-child th": { border: 0 },
                        }
                      }
                    >
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {item?.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {moment(item?.createdAt).format("YYYY-MM-DD")}
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                      >
                        <Avatar src={item?.url} alt={item?.name} variant="rounded" sx={{ width: 50, height: 50 }} />
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                      >
                        {item && item?.size < 1024 * 1024 ? `${Math.round((item?.size / 1024) * 100) / 100} KB` : `${Math.round((item?.size / (1024 * 1024)) * 100) / 100} MB`}
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          fontWeight: 500,
                          fontSize: "14px",
                          textTransform: "capitalize",
                          color: theme.palette.text.white,
                        }}
                      >
                        <Delete
                          onClick={() => setOpenUploadFileDelete(true)}
                          titleAccess="Delete File"
                          sx={{
                            marginRight: 1,
                            color: "var(--color-red)",
                            cursor: "pointer",
                            "&:hover": {
                              opacity: 1,
                            },
                          }}
                        />
                        <ContentCopy
                          titleAccess="Copy Image URL"
                          sx={{ cursor: "pointer", marginLeft: "4px", fontSize: "21px", color: theme.palette.text.white, opacity: 0.7, "&:active": { opacity: 1 } }}
                          onClick={() => handleCopy(item?.url)}
                        />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow className="!h-5 flex justify-center items-center">
                  <TableCell
                    align="center"
                    colSpan={7}
                    sx={{
                      borderBottom: "none",
                      color: theme.palette.text.white,
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    <CloudOutlined sx={{ fontSize: "40px" }} />
                    <Typography>No data found!</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          <TablePagination
            component="div"
            count={uploadFileList?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
          />
        </Box>
      </Box>

      <DeleteUploadFileModal {...{ openUploadFileDelete, setOpenUploadFileDelete, currentFile }} />
    </Fragment>
  );
});

export default UploadFileListTable;
