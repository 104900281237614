import { Box, Typography, useTheme } from "@mui/material";
import SearchBar from "components/common/SearchBar";
import Layout from "components/Dashboard/Layout";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHelpMessageList } from "redux/features/help/helpSlice";
import { setHelpMessageLoader } from "redux/features/loader/loaderSlice";
import { getHelpMessage } from "server/helpMessage/helpMessage";
import HelpCenterTable from "./HelpCenterTable";

const HelpCenter = memo(() => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const placeholderText = "Search subject,email,login";

  const handleSearchTextChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  const fetchedHelpMessageList = async () => {
    try {
      dispatch(setHelpMessageLoader(true));
      const result = await getHelpMessage();
      if (result?.data?.success) {
        dispatch(setHelpMessageList(result?.data?.data));
        dispatch(setHelpMessageLoader(false));
      } else {
        dispatch(setHelpMessageLoader(false));
      }
    } catch (error) {
      dispatch(setHelpMessageLoader(false));
    }
  };

  useEffect(() => {
    fetchedHelpMessageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Box>
        <Box className="flex items-center justify-between my-4">
          <Typography variant="h6" style={{ marginLeft: "15px", color: theme.palette.text.white }}>
            Help Center
          </Typography>
          <Box sx={{ marginRight: "15px", display: "flex", alignItems: "center", gap: 2 }}>
            <Box>
              <SearchBar {...{ searchText, handleSearchTextChange, placeholderText }} />
            </Box>
          </Box>
        </Box>
        <HelpCenterTable {...{ searchText }} />
      </Box>
    </Layout>
  );
});

export default HelpCenter;
