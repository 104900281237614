import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";

const BalanceChart = () => {
  const brokerList = useSelector((state) => state.broker.brokerList);

  const [options] = useState({
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        donut: {
          size: "70%",
          background: "transparent",
          labels: {
            show: false,
            name: {
              show: false,
              fontSize: "22px",
              color: "#FFFFFF",
            },
            value: {
              show: true,
              fontSize: "16px",
              color: "#FFFFFF",
            },
            total: {
              show: false,
              label: "RTP",
              color: "#FFFFFF",
              fontSize: "18px",
              formatter: () => "98%",
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "diagonal1",
        shadeIntensity: 0.5,
        gradientToColors: ["#A4E4FF", "#D29FF9"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 100],
      },
    },
    colors: ["#D29FF9", "#d5d5f5"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: false,
      width: 0,
      colors: ["#fff"],
      curve: "smooth",
    },
  });

  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const nameCounts = brokerList.reduce((acc, item) => {
      acc[item.name] = (acc[item.name] || 0) + 1;
      return acc;
    }, {});

    const seriesData = Object.values(nameCounts);
    const labelsData = Object.keys(nameCounts);

    setSeries(seriesData);
    setLabels(labelsData);
  }, [brokerList]);

  return (
    <div className="">
      <Chart options={{ ...options, labels }} series={series} type="donut" width="300" />
    </div>
  );
};

export default BalanceChart;
