import api from "api";
import { getAuthToken } from "server/auth/auth";
export async function getForceUpdateList() {
    try {
        return await api.get("/force-update", {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getForceUpdateList ~ error:", error)
    }
}

export async function getSingleForceUpdateList(id) {
    try {
        return await api.get(`/force-update/${id}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getSingleForceUpdateList ~ error:", error)
    }
}

export async function createForceUpdate(data) {
    try {
        return await api.post("/force-update", data, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ createForceUpdate ~ error:", error)
    }
}


export async function updateForceUpdate(data) {
    try {
        return await api.put("/force-update", data, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ updateForceUpdate ~ error:", error)
    }
}

export async function deleteForceUpdate(id) {
    try {
        return await api.delete(`/force-update/${id}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ deleteForceUpdate ~ error:", error)
    }
}
