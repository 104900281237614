import { useTheme } from "@emotion/react";
import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addForceUpdateList } from "redux/features/forceUpdate/forceUpdateSlice";
import { createForceUpdate } from "server/forceUpdate/forceUpdate";
import * as yup from "yup";

const validationSchema = yup.object({
  type: yup.string("Enter your type").required("Type is required"),
  version: yup.string("Enter your version").required("Version is required"),
  force: yup.boolean(),
});

const CreateForceUpdateModal = memo(({ createForceUpdateModalOpen, setCreateForceUpdateModalOpen }) => {
  const forceUpdateList = useSelector((state) => state.force.forceUpdateList);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      type: "",
      version: "",
      force: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const data = {
          type: values.type,
          version: values.version,
          force: values.force,
        };
        const result = await createForceUpdate(data);
        if (result?.data?.success) {
          dispatch(addForceUpdateList(result?.data?.data));
          enqueueSnackbar(result?.data?.message, { variant: "success" });
          setCreateForceUpdateModalOpen(false);
          formik.resetForm();
        } else {
          enqueueSnackbar(result?.data?.message, { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("An error occurred while creating force update!", { variant: "error" });
      } finally {
        setLoading(false);
      }
    },
  });

  // Function to handle type change and automatically set the version
  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    formik.setFieldValue("type", selectedType);

    if (selectedType) {
      // Find the last version for the selected typeDevice
      const lastVersionItem = forceUpdateList.filter((item) => item.type === selectedType).sort((a, b) => b.version - a.version)[0];

      const newVersion = lastVersionItem ? parseInt(lastVersionItem.version) + 1 : 1;
      formik.setFieldValue("version", newVersion?.toString()); // Set the new version in Formik
    } else {
      formik.setFieldValue("version", ""); // Reset version if type is not selected
    }
  };

  const handleDependentFieldChange = (event, field) => {
    if (!formik.values.type) {
      enqueueSnackbar("Please select a type first!", { variant: "warning" });
    } else {
      formik.handleChange(event);
    }
  };

  return (
    <Modal open={createForceUpdateModalOpen} onClose={() => setCreateForceUpdateModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          backgroundColor: theme.palette.background.main,
          borderRadius: "5px",
          padding: "25px",
          outline: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={4} columnSpacing={3} alignItems={"center"}>
            <Grid item xs={6}>
              <Typography sx={{ color: theme.palette.text.white, fontWeight: 500 }} className="!capitalize">
                Create Force Update
              </Typography>
            </Grid>
            <Grid item xs={6} className="text-end">
              <IconButton
                onClick={() => {
                  setCreateForceUpdateModalOpen(false);
                  formik.resetForm();
                }}
                title="Close"
              >
                <HighlightOff />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Box className="space-y-6">
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="type">Type</InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.background.main,
                        },
                      },
                    }}
                    labelId="type"
                    name="type"
                    id="type"
                    label="Type"
                    value={formik.values.type}
                    onChange={handleTypeChange} // Use the new handleTypeChange function
                    onBlur={formik.handleBlur}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                  >
                    <MenuItem value="" disabled key={"first"}>
                      Select type
                    </MenuItem>
                    <MenuItem value="android">Android</MenuItem>
                    <MenuItem value="windows">Windows</MenuItem>
                    <MenuItem value="macos">macOS</MenuItem>
                    <MenuItem value="ios">iOS</MenuItem>
                  </Select>
                  {formik.touched.type && formik.errors.type && <Typography className="!text-[#f44336] !text-[12px] !mt-1">{formik.touched.type && formik.errors.type}</Typography>}
                </FormControl>

                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                    },
                  }}
                >
                  <TextField
                    label="Version"
                    type="text"
                    id="version"
                    name="version"
                    size="small"
                    placeholder="Your version"
                    value={formik.values.version}
                    onChange={(event) => handleDependentFieldChange(event, "version")}
                    error={formik.touched.version && Boolean(formik.errors.version)}
                    helperText={formik.touched.version && formik.errors.version}
                  />
                </FormControl>

                <FormControl>
                  <FormControlLabel
                    sx={{
                      color: theme.palette.text.white,
                      "& .MuiFormControlLabel-label": {
                        fontSize: "15px",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={formik.values.force}
                        onChange={(event) => handleDependentFieldChange(event, "force")} // Add validation on force checkbox
                        name="force"
                        sx={{
                          color: "var(--primary-color)",
                          "&.Mui-checked": {
                            color: "var(--secondary-color)",
                          },
                        }}
                      />
                    }
                    label="Force Update"
                  />
                  {formik.touched.force && formik.errors.force && <Typography className="!text-[#f44336] !text-[12px] !mt-1">{formik.errors.force}</Typography>}
                </FormControl>

                <Box className="flex justify-end">
                  <Button
                    sx={{
                      background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                      boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                      color: "white",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#6553D8",
                      },
                    }}
                    disabled={loading}
                    type="submit"
                    size="medium"
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : "Create"}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
});

export default CreateForceUpdateModal;
