import api from "api";
import { getAuthToken } from "server/auth/auth";
export async function getBrokerList() {
    try {
        return await api.get("/broker", {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getBrokerList ~ error:", error)
    }
}

export async function addBroker(data) {
    try {
        return await api.post("/broker", data, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ addBroker ~ error:", error)
    }
}

export async function createWhiteLabelBroker(data) {
    try {
        return await api.post("/broker/white-label", data, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ createWhiteLabelBroker ~ error:", error)
    }
}

export async function updateBroker(data) {
    try {
        return await api.put("/broker", data, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ updateBroker ~ error:", error)
    }
}
export async function updateWhiteLabelBroker(data) {
    try {
        return await api.post("/broker/white-label", data, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ updateWhiteLabelBroker ~ error:", error)
    }
}

export async function deleteBroker(id) {
    try {
        return await api.delete(`/broker/${id}`,
            {
                headers: {
                    Authorization: getAuthToken(),
                },
            }
        );
    } catch (error) {
        console.log("🚀 ~ deleteBroker ~ error:", error)
    }
}
