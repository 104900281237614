import { Delete, Edit, Verified } from "@mui/icons-material";
import { ListItemIcon, Menu, MenuItem, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { Fragment, memo, useCallback, useState } from "react";
import { licenseValidate } from "server/license/license";
import DeleteLicenseModal from "./DeleteLicenseModal";

const LicenseTableActionPopOver = memo(
  ({ anchorElLicenseActionPopover, licenseActionPopoverOpen, handleCloseLicenseActionPopover, setUpdateLicenseModalOpen, currentRowLicense }) => {
    const [deleteLicenseModalOpen, setDeleteLicenseModalOpen] = useState(false);
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const handleLicenseValidate = useCallback(async () => {
      try {
        const result = await licenseValidate({ license_key: currentRowLicense?.license_key });
        if (result?.data?.success) {
          enqueueSnackbar(result?.data?.message, { variant: "success" });
        } else {
          enqueueSnackbar(result?.data?.message || "Failed to validate license", { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("An error occurred during license validation", { variant: "error" });
      } finally {
        handleCloseLicenseActionPopover();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRowLicense?.license_key]);

    const handleDeleteModalOpen = useCallback(() => {
      setDeleteLicenseModalOpen(true);
      handleCloseLicenseActionPopover();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpdateModalOpen = useCallback(() => {
      setUpdateLicenseModalOpen(true);
      handleCloseLicenseActionPopover();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Fragment>
        <Menu
          anchorEl={anchorElLicenseActionPopover}
          open={licenseActionPopoverOpen}
          onClose={handleCloseLicenseActionPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: theme.palette.background.main,
            },
          }}
        >
          <MenuItem sx={{ fontSize: "14px" }} onClick={() => handleLicenseValidate()}>
            <ListItemIcon>
              <Verified sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            Validate
          </MenuItem>
          <MenuItem sx={{ fontSize: "14px" }} onClick={handleUpdateModalOpen}>
            <ListItemIcon>
              <Edit sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem sx={{ fontSize: "14px" }} onClick={handleDeleteModalOpen}>
            <ListItemIcon>
              <Delete sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>
        <DeleteLicenseModal {...{ deleteLicenseModalOpen, setDeleteLicenseModalOpen, currentRowLicense }} />
      </Fragment>
    );
  }
);

export default LicenseTableActionPopOver;
