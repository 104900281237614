import { Delete, Edit } from "@mui/icons-material";
import { ListItemIcon, Menu, MenuItem, useTheme } from "@mui/material";
import { Fragment, memo, useCallback } from "react";

const ForceUpdateActionPopover = memo(
  ({ forceUpdateActionPopover, forceUpdateActionPopoverOpen, handleCloseForceUpdateActionPopover, setUpdateForceUpdateModalOpen, setDeleteForceUpdateModalOpen }) => {
    const theme = useTheme();

    const handleUpdateForceUpdate = useCallback(() => {
      setUpdateForceUpdateModalOpen(true);
      handleCloseForceUpdateActionPopover();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDeleteForceUpdate = useCallback(() => {
      setDeleteForceUpdateModalOpen(true);
      handleCloseForceUpdateActionPopover();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Fragment>
        <Menu
          anchorEl={forceUpdateActionPopover}
          open={forceUpdateActionPopoverOpen}
          onClose={handleCloseForceUpdateActionPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPaper-root": {
              overflow: "hidden",
              backgroundColor: theme.palette.background.main,
            },
          }}
        >
          <MenuItem sx={{ fontSize: "14px" }} onClick={handleUpdateForceUpdate}>
            <ListItemIcon>
              <Edit sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            Edit
          </MenuItem>

          <MenuItem sx={{ fontSize: "14px" }} onClick={handleDeleteForceUpdate}>
            <ListItemIcon>
              <Delete sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }
);

export default ForceUpdateActionPopover;
