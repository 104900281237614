import { useTheme } from "@emotion/react";
import { HighlightOff } from "@mui/icons-material";
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, Modal, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { updatePlanList } from "redux/features/plan/planSlice";
import { updatePlan } from "server/plan/plan";
import * as yup from "yup";

const validationSchema = yup.object({
  name: yup.string("Enter plan name").required("Plan name is required"),
  limit: yup.string("Enter limit").required("Limit is required"),
  demo_server: yup.boolean(),
});

const UpdatePlanModal = memo(({ updatePlanModalOpen, setUpdatePlanModalOpen, currentPlan }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      name: currentPlan?.name || "",
      limit: currentPlan?.limit || "",
      demo_server: currentPlan?.demo || false,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      const data = {
        id: currentPlan?._id,
        name: values.name,
        limit: values.limit,
        demo: values.demo_server,
      };
      try {
        const result = await updatePlan(data);
        if (result?.data?.success) {
          dispatch(updatePlanList(result?.data?.data));
          enqueueSnackbar(result?.data?.message, { variant: "success" });
          setUpdatePlanModalOpen(false);
          formik.resetForm();
        } else {
          enqueueSnackbar(result?.data?.message, { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("An unexpected error occurred. Please try again later.", { variant: "error" });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal open={updatePlanModalOpen} onClose={() => setUpdatePlanModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          backgroundColor: theme.palette.background.main,
          borderRadius: "5px",
          padding: "25px",
          outline: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={4} columnSpacing={3} alignItems={"center"}>
            <Grid item xs={6}>
              <Typography sx={{ color: theme.palette.text.white, fontWeight: 500 }} className="!capitalize">
                Update Plan
              </Typography>
            </Grid>
            <Grid item xs={6} className="text-end">
              <IconButton
                onClick={() => {
                  setUpdatePlanModalOpen(false);
                  formik.resetForm();
                }}
                title="Close"
              >
                <HighlightOff />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Box className="space-y-6">
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                    },
                  }}
                >
                  <TextField
                    label="Name"
                    type="text"
                    id="name"
                    name="name"
                    size="small"
                    placeholder="Your plan name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </FormControl>

                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                    },
                  }}
                >
                  <TextField
                    label="Limit"
                    type="text"
                    id="limit"
                    name="limit"
                    size="small"
                    placeholder="Your plan limit"
                    value={formik.values.limit}
                    onChange={formik.handleChange}
                    error={formik.touched.limit && Boolean(formik.errors.limit)}
                    helperText={formik.touched.limit && formik.errors.limit}
                  />
                </FormControl>

                <FormControl>
                  <FormControlLabel
                    sx={{
                      color: theme.palette.text.white,
                      "& .MuiFormControlLabel-label": {
                        fontSize: "15px",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={formik.values.demo_server}
                        onChange={formik.handleChange}
                        name="demo_server"
                        sx={{
                          color: "var(--primary-color)",
                          "&.Mui-checked": {
                            color: "var(--secondary-color)",
                          },
                        }}
                      />
                    }
                    label="Demo Server"
                  />
                  {formik.touched.demo_server && formik.errors.demo_server && <Typography className="!text-[#f44336] !text-[12px] !mt-1">{formik.errors.demo_server}</Typography>}
                </FormControl>

                <Box className="flex justify-end">
                  <Button
                    sx={{
                      background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                      boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                      color: "white",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      width: "100px",
                      pointerEvents: loading ? "none" : "auto",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : "Update"}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
});

export default UpdatePlanModal;
