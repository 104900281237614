import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    planList: [],
}

export const planSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        setPlanList: (state, action) => {
            state.planList = action.payload
        },
        addPlanList: (state, action) => {
            state.planList.push(action.payload)
        },
        updatePlanList: (state, action) => {
            const index = state.planList.findIndex(plan => plan._id === action.payload._id)
            if (index !== -1) {
                state.planList[index] = {
                    ...state.planList[index],
                    ...action.payload,
                }
            }
        },
        removePlan: (state, action) => {
            state.planList = state.planList.filter(plan => plan._id !== action.payload)
        },
    },
})

export const { setPlanList, removePlan, addPlanList, updatePlanList } = planSlice.actions

export default planSlice.reducer