import { Delete, Edit } from "@mui/icons-material";
import { ListItemIcon, Menu, MenuItem, useTheme } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { Fragment, memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { removeBroker } from "redux/features/broker/brokerSlice";
import { deleteBroker } from "server/broker/broker";
import DeleteBrokerModal from "./DeleteBrokerModal";

const BrokerActionPopover = memo(
  ({ brokerActionPopover, brokerActionPopoverOpen, handleCloseBrokerActionPopover, selectedBroker, setBrokerUpdateModal, setWhiteLabelBrokerUpdateModal }) => {
    const [loading, setLoading] = useState(false);
    const [deleteBrokerModal, setDeleteBrokerModal] = useState(false);
    const theme = useTheme();
    const dispatch = useDispatch();

    const brokerDelete = useCallback(async () => {
      setLoading(true);
      try {
        const res = await deleteBroker(selectedBroker?._id);
        if (res?.data?.success) {
          enqueueSnackbar(res?.data?.message, { variant: "success" });
          dispatch(removeBroker(selectedBroker?._id));
          handleCloseBrokerActionPopover();
          setLoading(false);
          setDeleteBrokerModal(false);
        } else {
          handleCloseBrokerActionPopover();
          setDeleteBrokerModal(false);
        }
      } catch (error) {
        handleCloseBrokerActionPopover();
        setLoading(false);
        setDeleteBrokerModal(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBroker?._id]);

    const handleBrokerUpdateModalOpen = useCallback(() => {
      if (selectedBroker && selectedBroker?.broker_type === "admin") {
        setBrokerUpdateModal(true);
      } else {
        setWhiteLabelBrokerUpdateModal(true);
      }
      handleCloseBrokerActionPopover();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBroker?.broker_type]);

    return (
      <Fragment>
        <Menu
          anchorEl={brokerActionPopover}
          open={brokerActionPopoverOpen}
          onClose={handleCloseBrokerActionPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPaper-root": {
              overflow: "hidden",
              backgroundColor: theme.palette.background.main,
            },
          }}
        >
          <MenuItem sx={{ fontSize: "14px" }} onClick={handleBrokerUpdateModalOpen}>
            <ListItemIcon>
              <Edit sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            Edit
          </MenuItem>

          <MenuItem sx={{ fontSize: "14px" }} onClick={() => setDeleteBrokerModal(true)}>
            <ListItemIcon>
              <Delete sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>
        <DeleteBrokerModal {...{ deleteBrokerModal, setDeleteBrokerModal, selectedBroker, brokerDelete, loading }} />
      </Fragment>
    );
  }
);

export default BrokerActionPopover;
