import api from "api";
import { getAuthToken } from "server/auth/auth";
export async function getBackendUpdateList() {
    try {
        return await api.get("/backend-update", {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getBackendUpdateList ~ error:", error)
    }
}

export async function createBackendUpdate(data) {
    try {
        return await api.post("/backend-update", data, {
            headers: {
                Authorization: getAuthToken(),
                "Content-Type": "multipart/form-data",
            },
        });
    } catch (error) {
        console.log("🚀 ~ createBackendUpdate ~ error:", error)
    }
}


export async function updateBackendUpdate(data) {
    try {
        return await api.put("/backend-update", data, {
            headers: {
                Authorization: getAuthToken(),
                "Content-Type": "multipart/form-data",
            },
        });
    } catch (error) {
        console.log("🚀 ~ updateBackendUpdate ~ error:", error)
    }
}

export async function deleteBackendUpdate(id) {
    try {
        return await api.delete(`/backend-update/${id}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ deleteBackendUpdate ~ error:", error)
    }
}
