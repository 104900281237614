import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  themeMode: localStorage.getItem('themeMode') || 'dark'
}

export const themeSlice = createSlice({
  name: 'themeSlice',
  initialState,
  reducers: {
    setThemeMode: (state, action) => {
      state.themeMode = action.payload;
      localStorage.setItem('themeMode', action.payload);
    },
  },
})

export const { setThemeMode } = themeSlice.actions

export default themeSlice.reducer