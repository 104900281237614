import { ContentCopy } from "@mui/icons-material";
import AddPhotoIcon from "@mui/icons-material/AddPhotoAlternate";
import UploadDoneIcon from "@mui/icons-material/FileDownloadDone";
import InsertFile from "@mui/icons-material/InsertDriveFile";
import { Box, Button, CircularProgress, IconButton, Typography, useTheme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Layout from "components/Dashboard/Layout";
import { useSnackbar } from "notistack";
import { memo, useCallback, useState } from "react";
import Dropzone from "react-dropzone";
import { uploadFile } from "server/upload/uploadFile";

const UploadFile = memo(() => {
  const theme = useTheme();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progressInfos, setProgressInfos] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [resultData, setResultData] = useState({});

  const uploadFiles = async () => {
    setLoading(true);
    let _progressInfos = selectedFiles?.map((file) => ({
      percentage: 0,
      fileName: file.name,
    }));

    setProgressInfos(_progressInfos);

    try {
      const file = selectedFiles[0];
      const formData = new FormData();
      formData.append("file", file);

      // Start tracking upload progress
      await simulateUploadProgress(_progressInfos, formData);
    } catch (error) {
      enqueueSnackbar("An error occurred during the upload.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const simulateUploadProgress = (progressInfos, formData) => {
    return new Promise(async (resolve) => {
      for (let i = 0; i < 10; i++) {
        await new Promise((r) => setTimeout(r, 500));
        setProgressInfos((prevProgressInfos) => {
          const newProgressInfos = [...prevProgressInfos];
          newProgressInfos[0] = {
            ...newProgressInfos[0],
            percentage: (i + 1) * 10,
          };
          return newProgressInfos;
        });
      }

      // Assuming uploadFile returns a promise
      const result = await uploadFile(formData);
      if (result?.data?.success) {
        setResultData(result?.data?.data);
        enqueueSnackbar(result?.data?.message, { variant: "success" });
      } else {
        enqueueSnackbar(result?.data?.message || "An error occurred during the upload.", { variant: "error" });
      }

      resolve();
    });
  };

  const onDrop = useCallback((files) => {
    if (files?.length > 0) {
      setSelectedFiles(files);
    }
  }, []);

  const handleCopy = useCallback((text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar("Live image url copied to clipboard!", { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar("Failed to copy live image url. Please try again.", { variant: "error" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Box className="flex flex-col justify-center items-center" sx={{ height: `calc(100vh - 64px)` }}>
        <Box
          className="flex flex-col justify-center items-center"
          sx={{
            width: "70%",
            height: "70%",
            margin: "0 auto",
            padding: "30px 0",
            border: `2px solid ${theme.palette.primary.borderColor}`,
            borderRadius: "20px",
            backgroundColor: theme.palette.background.card1,
          }}
        >
          <Box className="text-center">
            <Typography
              className="pb-2"
              sx={{
                fontSize: "32px",
                color: theme.palette.text.white,
              }}
            >
              Upload File
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                color: theme.palette.text.secondaryText,
              }}
            >
              Upload documents you want to share <br />
              with our team
            </Typography>
          </Box>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <Box
                  {...getRootProps({ className: "dropzone" })}
                  sx={{
                    width: {
                      md: "100%",
                      lg: 500,
                    },
                    height: 250,
                    margin: "20px auto",
                    cursor: "pointer",
                    border: `2px dashed ${theme.palette.primary.border2}`,
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    overflow: "hidden",
                    backgroundColor: `${theme.palette.background.card2}`,
                  }}
                >
                  <input {...getInputProps()} />
                  {selectedFiles && selectedFiles.length ? (
                    <Box className="selected-file w-full h-full" sx={{ color: theme.palette.text.white }}>
                      <img className="w-full h-full object-fill" src={URL.createObjectURL(selectedFiles[0])} alt={selectedFiles[0]?.name} />
                    </Box>
                  ) : (
                    <Box className="flex flex-col justify-center items-center gap-3 box-border p-1">
                      <AddPhotoIcon sx={{ color: "var(--icon-color)", fontSize: "42px" }} />
                      <Typography sx={{ color: theme.palette.text.secondaryText }}>Drag and drop files here, or click to browse files</Typography>
                    </Box>
                  )}
                </Box>
              </section>
            )}
          </Dropzone>

          {selectedFiles && selectedFiles.length > 0 && (
            <Button
              sx={{
                background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                color: "white",
                fontWeight: 500,
                fontSize: "16px",
                textTransform: "capitalize",
                width: "500px",
              }}
              type="button"
              variant="contained"
              onClick={uploadFiles}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} sx={{ color: "var(--color-white)" }} /> : "Upload"}
            </Button>
          )}

          {progressInfos?.length > 0 && (
            <Box className="w-[500px] m-auto">
              <Typography
                sx={{
                  fontSize: "18px",
                  color: theme.palette.text.white,
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                Uploads Files
              </Typography>
              {progressInfos?.map((progressInfo, index) => (
                <Box
                  className="mb-2 rounded-md px-3 py-2"
                  key={index}
                  sx={{
                    backgroundColor: theme.palette.background.card1,
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <Box className="flex justify-start items-center gap-3">
                    <InsertFile sx={{ color: "var(--icon-color)", fontSize: "48px" }} />
                    <Box className="w-full" title={progressInfo.fileName}>
                      <Box className="flex justify-between items-center mb-2">
                        <Box>
                          <Typography className="mb-5" sx={{ color: theme.palette.text.white }}>
                            {progressInfo.fileName.slice(0, 20)}...
                          </Typography>
                        </Box>
                        <Box>
                          <Typography sx={{ color: theme.palette.text.white }}>{progressInfo.percentage}%</Typography>
                        </Box>
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress
                          variant="determinate"
                          value={progressInfo?.percentage}
                          sx={{
                            background: "#f0eef9",
                            height: "8px",
                            borderRadius: "5px",
                            "& .MuiLinearProgress-bar": {
                              background: "linear-gradient(to right, #A4E4FF, #D29FF9)",
                              borderRadius: "5px",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                    {progressInfo?.percentage === 100 && <UploadDoneIcon sx={{ color: "var(--active-color)" }} />}
                    {resultData && resultData?.url && (
                      <IconButton title="Copy Live Image URL" onClick={() => handleCopy(resultData?.url)}>
                        <ContentCopy sx={{ color: "var(--icon-color)", fontSize: "22px" }} />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Layout>
  );
});

export default UploadFile;
