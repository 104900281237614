import { useTheme } from "@emotion/react";
import { HighlightOff } from "@mui/icons-material";
import { Box, Button, CircularProgress, FormControl, Grid, IconButton, Modal, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateHelpMessageList } from "redux/features/help/helpSlice";
import { updateHelpMessage } from "server/helpMessage/helpMessage";
import * as yup from "yup";

const validationSchema = yup.object({
  subject: yup.string("Enter subject message").required("Subject message is required"),
  message: yup.string("Enter your message").required("Message is required"),
});

const UpdateHelpModal = memo(({ updateHelpModalOpen, setUpdateHelpModalOpen, currentHelpRow }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      subject: currentHelpRow?.subject || "",
      message: currentHelpRow?.message || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      const data = {
        id: currentHelpRow?._id,
        subject: values.subject,
        message: values.message,
        brokerId: currentHelpRow?.brokerId,
        email: currentHelpRow?.email,
        login: currentHelpRow?.login,
      };
      try {
        const result = await updateHelpMessage(data);
        if (result?.data?.success) {
          dispatch(updateHelpMessageList(result?.data?.data));
          enqueueSnackbar(result?.data?.message, { variant: "success" });
          setUpdateHelpModalOpen(false);
          formik.resetForm();
        } else {
          enqueueSnackbar(result?.data?.message, { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("An unexpected error occurred. Please try again later.", { variant: "error" });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal open={updateHelpModalOpen} onClose={() => setUpdateHelpModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          backgroundColor: theme.palette.background.main,
          borderRadius: "5px",
          padding: "25px",
          outline: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={4} columnSpacing={3} alignItems={"center"}>
            <Grid item xs={6}>
              <Typography sx={{ color: theme.palette.text.white, fontWeight: 500 }} className="!capitalize">
                Update Help
              </Typography>
            </Grid>
            <Grid item xs={6} className="text-end">
              <IconButton
                onClick={() => {
                  setUpdateHelpModalOpen(false);
                  formik.resetForm();
                }}
                title="Close"
              >
                <HighlightOff />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Box className="space-y-6">
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                    },
                  }}
                >
                  <TextField
                    label="Subject"
                    type="text"
                    id="subject"
                    name="subject"
                    size="small"
                    placeholder="Your subject"
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                    error={formik.touched.subject && Boolean(formik.errors.subject)}
                    helperText={formik.touched.subject && formik.errors.subject}
                  />
                </FormControl>

                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                    },
                  }}
                >
                  <TextField
                    multiline
                    rows={4}
                    label="Message"
                    type="text"
                    id="message"
                    name="message"
                    size="small"
                    placeholder="Your message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                    helperText={formik.touched.message && formik.errors.message}
                  />
                </FormControl>

                <Box className="flex justify-end">
                  <Button
                    sx={{
                      background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                      boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                      color: "white",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      width: "100px",
                      pointerEvents: loading ? "none" : "auto",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : "Update"}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
});

export default UpdateHelpModal;
