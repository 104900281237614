import { useTheme } from "@emotion/react";
import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { memo, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBackendResultList } from "redux/features/backendResult/backendResultSlice";
import { createBackendResult } from "server/backendResult/backendUpdate";
import * as yup from "yup";

const validationSchema = yup.object({
  version: yup.string("Enter your version").required("Version is required"),
  broker_id: yup.string("Select broker").required("Broker is required"),
});

const CreateBackendResultModal = memo(({ createBackendResultModalOpen, setCreateBackendResultModalOpen }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const brokerList = useSelector((state) => state.broker.brokerList);
  const brokerListAdmin = useMemo(
    () => brokerList?.filter((broker) => broker?.broker_type === "admin") || [],
    [brokerList]
  );


  const formik = useFormik({
    initialValues: {
      version: "",
      broker_id: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("version", values.version);
        formData.append("broker_id", values.broker_id);

        const result = await createBackendResult(formData);
        if (result?.data?.success) {
          dispatch(addBackendResultList(result?.data?.data));
          enqueueSnackbar(result?.data?.message, { variant: "success" });
          setCreateBackendResultModalOpen(false);
          formik.resetForm();
        } else {
          enqueueSnackbar(result?.data?.message, { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("An error occurred while creating backend result!", { variant: "error" });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal open={createBackendResultModalOpen} onClose={() => setCreateBackendResultModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          backgroundColor: theme.palette.background.main,
          borderRadius: "5px",
          padding: "25px",
          outline: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={4} columnSpacing={3} alignItems={"center"}>
            <Grid item xs={6}>
              <Typography sx={{ color: theme.palette.text.white, fontWeight: 500 }} className="!capitalize">
                Create Backend Result
              </Typography>
            </Grid>
            <Grid item xs={6} className="text-end">
              <IconButton
                onClick={() => {
                  setCreateBackendResultModalOpen(false);
                  formik.resetForm();
                }}
                title="Close"
              >
                <HighlightOff />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Box className="space-y-6">

                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                    },
                  }}
                >
                  <TextField
                    label="Version"
                    type="text"
                    id="version"
                    name="version"
                    size="small"
                    placeholder="Your version 1"
                    value={formik.values.version}
                    onChange={formik.handleChange}
                    error={formik.touched.version && Boolean(formik.errors.version)}
                    helperText={formik.touched.version && formik.errors.version}
                  />
                </FormControl>

                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="broker_id">Broker</InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.background.main,
                        },
                      },
                    }}
                    labelId="broker_id"
                    name="broker_id"
                    id="broker_id"
                    label="Broker"
                    value={formik.values.broker_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.broker_id && Boolean(formik.errors.broker_id)}
                  >
                    <MenuItem value="" disabled key={"first"}>
                      Select broker (optional)
                    </MenuItem>
                    {brokerListAdmin?.map((broker) => (
                      <MenuItem value={broker?.broker_id} key={broker?._id}>
                        {broker?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.broker_id && formik.errors.broker_id && (
                    <Typography className="!text-[#f44336] !text-[12px] !mt-1">{formik.touched.broker_id && formik.errors.broker_id}</Typography>
                  )}
                </FormControl>

                <Box className="flex justify-end">
                  <Button
                    sx={{
                      background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                      boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                      color: "white",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#6553D8",
                      },
                    }}
                    disabled={loading}
                    type="submit"
                    size="medium"
                  >
                    {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Create"}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
});

export default CreateBackendResultModal;
