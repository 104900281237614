import { Search } from "@mui/icons-material";
import { FormControl, InputAdornment, TextField, useTheme } from "@mui/material";
import { memo } from "react";

const SearchBar = memo(({ searchText, handleSearchTextChange, placeholderText }) => {
  const theme = useTheme();
  return (
    <FormControl
      sx={{
        width: "100%",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "1px solid var(--primary-color)",
          },
          "&:hover fieldset": {
            border: "1px solid var(--secondary-color)",
          },
          "&.Mui-focused fieldset": {
            border: `1px solid var(--secondary-color)`,
          },
        },
        "& .MuiInputLabel-root": {
          color: theme.palette.text.white,
          "&.Mui-focused": {
            color: theme.palette.text.white,
          },
        },
      }}
    >
      <TextField
        placeholder={placeholderText}
        size="small"
        value={searchText}
        onChange={handleSearchTextChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search
                sx={{
                  color: "var(--text-color)",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
});

export default SearchBar;
