import { useTheme } from "@emotion/react";
import { HighlightOff } from "@mui/icons-material";
import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFormik } from "formik";
import moment from "moment";
import { useSnackbar } from "notistack";
import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addLicenseList } from "redux/features/license/licenseSlice";
import { createSlice } from "server/license/license";
import * as yup from "yup";

const validationSchema = yup.object({
  status: yup.string("Select status").required("Status is required"),
  // broker: yup.string("Select broker").required("Broker is required"),
  expire_date: yup.string("Select expire date").required("Expire date is required"),
});

const CreateLicenseModal = memo(({ createLicenseModalOpen, setCreateLicenseModalOpen }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const brokerList = useSelector((state) => state.broker.brokerList);
  const formik = useFormik({
    initialValues: {
      broker: "",
      status: "",
      expire_date: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const expire_date = moment(values?.expire_date?.$d).format("YYYY-MM-DD");
        const result = await createSlice({ broker_id: values.broker, expire_date: expire_date, status: values.status });
        if (result?.data?.success) {
          dispatch(addLicenseList(result?.data?.data));
          enqueueSnackbar(result?.data?.message, { variant: "success" });
          setCreateLicenseModalOpen(false);
          formik.resetForm();
        } else {
          enqueueSnackbar(result?.data?.message, { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("An error occurred while create license!", { variant: "error" });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal open={createLicenseModalOpen} onClose={() => setCreateLicenseModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          backgroundColor: theme.palette.background.main,
          borderRadius: "5px",
          padding: "25px",
          outline: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={4} columnSpacing={3} alignItems={"center"}>
            <Grid item xs={6}>
              <Typography sx={{ color: theme.palette.text.white }} className="!capitalize">
                Create License
              </Typography>
            </Grid>
            <Grid item xs={6} className="text-end">
              <IconButton onClick={() => setCreateLicenseModalOpen(false)} title="Close">
                <HighlightOff />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Box className="space-y-6">
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="broker">Broker</InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.background.main,
                        },
                      },
                    }}
                    labelId="broker"
                    name="broker"
                    id="broker"
                    label="Broker"
                    value={formik.values.broker}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.broker && Boolean(formik.errors.broker)}
                  >
                    <MenuItem value="" disabled key={"first"}>
                      Select broker (optional)
                    </MenuItem>
                    {brokerList?.map((broker) => (
                      <MenuItem value={broker?._id} key={broker?._id}>
                        {broker?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.broker && formik.errors.broker && (
                    <Typography className="!text-[#f44336] !text-[12px] !mt-1">{formik.touched.broker && formik.errors.broker}</Typography>
                  )}
                </FormControl>

                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                      textAlign: "center",
                    },
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Expire Date"
                      disablePast
                      slotProps={{ textField: { size: "small" } }}
                      name="expire_date"
                      value={formik.values.expire_date}
                      onChange={(value) => formik.setFieldValue("expire_date", value)}
                      onBlur={formik.handleBlur}
                      error={formik.touched.expire_date && Boolean(formik.errors.expire_date)}
                    />
                  </LocalizationProvider>
                  {formik.touched.expire_date && formik.errors.expire_date && (
                    <Typography className="!text-[#f44336] !text-[12px] !mt-1">{formik.touched.expire_date && formik.errors.expire_date}</Typography>
                  )}
                </FormControl>

                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="status">Status</InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.background.main,
                        },
                      },
                    }}
                    labelId="status"
                    id="status"
                    label="Status"
                    name="status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.status && Boolean(formik.errors.status)}
                  >
                    <MenuItem value="" disabled key={"first"}>
                      Select Status
                    </MenuItem>
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"inactive"}>Inactive</MenuItem>
                  </Select>
                  {formik.touched.status && formik.errors.status && (
                    <Typography className="!text-[#f44336] !text-[12px] !mt-1">{formik.touched.status && formik.errors.status}</Typography>
                  )}
                </FormControl>

                <Box className="flex justify-end">
                  <Button
                    sx={{
                      background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                      boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                      color: "white",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      width: "100px",
                      pointerEvents: loading ? "none" : "auto",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : "Create"}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
});

export default CreateLicenseModal;
