import { CloudOutlined } from "@mui/icons-material";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import LatestVersionLoader from "components/loader/LatestVersionLoader";
import PlanLoaderDashboard from "components/loader/PlanLoaderDashboard";
import { memo, useMemo } from "react";
import { FaAndroid, FaApple, FaWindows } from "react-icons/fa";
import { useSelector } from "react-redux";
import BalanceCard from "./BalanceCard";

const HeroSection = memo(() => {
  const planList = useSelector((state) => state.plan.planList);
  const planLoading = useSelector((state) => state.loader.planLoader);
  const forceUpdateLoading = useSelector((state) => state.loader.forceUpdateLoader);
  const theme = useTheme();
  const forceUpdateList = useSelector((state) => state.force.forceUpdateList);

  const getLatestVersions = (data) => {
    const latestVersions = {};
    data?.forEach((item) => {
      const { type, version } = item;
      const versionNumber = parseInt(version, 10);
      if (!latestVersions[type] || versionNumber > parseInt(latestVersions[type].version, 10)) {
        latestVersions[type] = { ...item, version: versionNumber };
      }
    });
    return Object.values(latestVersions);
  };
  const latestVersionList = useMemo(() => getLatestVersions(forceUpdateList), [forceUpdateList]);

  return (
    <Grid container rowSpacing={10} columnSpacing={5}>
      <Grid item xs={12} md={12} lg={6}>
        <Box sx={{ px: { xs: 2, md: 0 } }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6} lg={6}>
              <BalanceCard />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h6" sx={{ color: theme.palette.text.white }}>
                Latest Version
              </Typography>
              <Box className="flex flex-col gap-4 mt-5">
                {forceUpdateLoading ? (
                  <LatestVersionLoader />
                ) : latestVersionList?.length > 0 ? (
                  latestVersionList.map((item, i) => (
                    <Box
                      key={i}
                      className={`flex justify-between items-center h-[67px] p-3 rounded-lg`}
                      sx={{
                        bgcolor: theme.palette.primary.cardBg,
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                      }}
                    >
                      <Box className="flex flex-col gap-2 justify-center">
                        <Typography variant="p" className="text-[var(--text-color)]">
                          {item?.type === "android" ? "Android" : item?.type === "ios" ? "iOS" : item.type === "windows" ? "Windows" : "macOS"}
                        </Typography>
                        <Typography variant="h5" sx={{ color: theme.palette.text.white }}>
                          {item.version}
                        </Typography>
                      </Box>
                      <Box className="w-[100px] h-[80px] flex justify-center items-center">
                        {item?.type === "android" ? (
                          <FaAndroid style={{ color: theme.palette.text.white, fontSize: "48px" }} />
                        ) : item?.type === "ios" ? (
                          <FaApple style={{ color: theme.palette.text.white, fontSize: "48px" }} />
                        ) : item.type === "windows" ? (
                          <FaWindows style={{ color: theme.palette.text.white, fontSize: "48px" }} />
                        ) : (
                          <svg
                            height="48px"
                            width="48px"
                            id="Layer_1"
                            style={{
                              enableBackground: "new 0 0 48 48",
                            }}
                            fill={theme.palette.text.white}
                            viewBox="0 0 48 48"
                            xmlSpace="preserve"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                          >
                            <path d="M29.7,47c-3.7,0-5.5-3.3-6.2-5.7H2c-1.8,0-2-1.7-2-2.8L0,7.1c0-2,1.8-2.7,3-2.7l21,0c1.1-1.5,2.2-3.1,3.8-4.4l1.3,1.5  c-1,0.8-1.9,1.8-2.6,2.9h1v0.1l15.9-0.1c1.1,0,2.6,0.8,2.6,2.9v31.1c0,0.3,0,1.5-0.6,2.2c-0.3,0.4-0.9,0.8-2,0.8l-17.7,0l-0.2-2  l17.7,0c0.1,0,0.4,0,0.5-0.1c0.1-0.1,0.1-0.4,0.1-0.5c0,0,0-0.1,0-0.1L44,7.3c0-0.8-0.4-0.9-0.6-0.9L24.9,6.5  C24,7.6,21,11.6,19.6,19.1c-0.3,1.5-0.1,2.6-0.1,3l0.7,1.3l1.2-0.1c0,0,0,0,0,0c1.8,0,2.9,0.3,3.6,1c0.5,0.5,0.5,1.1,0.5,1.3l0,7.5  l4.1,0.2c3.1,0,6.2-3,6.5-3.4c0,0,0-0.1-0.1-0.2l1.7-1.1c0.7,1.1,0.4,2.1-0.2,2.7c-0.7,0.7-4.2,3.9-8,3.9l-4.1-0.2l0,5.1  c0,0.3-0.1,0.4-0.4,0.5c0.7,1.8,2.3,4.2,4.6,4.2V47z M22.5,6.4l-19.4,0c-0.2,0-1,0.1-1,0.7l0.2,31.3c0,0.2-0.4,1,0,1h21.2v-1.8  c0-0.1,0-0.2,0-0.2l-0.1-2h-5.9c-0.3,0-7.5,0.3-10.2-4.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2-0.2-1.2,0.5-1.9c0.3-0.3,0.8-0.7,1.8-0.7v2  c-0.2,0-0.2,0-0.2,0c0,0,0,0.1,0,0.1c2.2,3.4,8.2,3.1,8.3,3.1h6.1v-7.6c-0.1-0.1-0.6-0.4-2-0.4l-1.8,0.1c-0.4,0-0.8-0.2-1-0.6  l-1-2.1c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.3-1.7,0.1-3.9C18.8,12.4,21.1,8.4,22.5,6.4z M31.6,16.6c-1.4,0-3.4-1.1-3.4-2.7v-1.7  c0-1.6,2.1-2.7,3.4-2.7s3.4,1.1,3.4,2.7v1.7C35.1,15.5,33,16.6,31.6,16.6z M30.2,12.2v1.7c0.1,0.2,0.9,0.7,1.4,0.7s1.4-0.5,1.4-0.7  l0-1.7c-0.1-0.2-0.9-0.7-1.4-0.7C31.1,11.5,30.3,12,30.2,12.2z M12.8,16.6c-1.2,0-3.9-0.9-3.9-2.7v-1.7c0-1.8,2.8-2.7,3.9-2.7  c1.5,0,3.2,1.2,3.2,2.7v1.7C16,15.4,14.2,16.6,12.8,16.6z M10.9,13.8c0.2,0.3,1.3,0.8,1.9,0.8c0.5,0,1.2-0.5,1.2-0.7v-1.7  c0-0.2-0.6-0.7-1.2-0.7c-0.6,0-1.7,0.5-1.9,0.8V13.8z" />
                          </svg>
                        )}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      color: theme.palette.text.white,
                      fontWeight: 500,
                      fontSize: "14px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "312px",
                      border: `2px solid ${theme.palette.primary.border}`,
                      borderRadius: "10px",
                    }}
                  >
                    <CloudOutlined sx={{ fontSize: "40px" }} />
                    <Typography>Latest version not found!</Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {/* Right side start here */}
      <Grid item xs={12} md={12} lg={6}>
        <Box>
          <Box className="flex justify-between items-center">
            <Typography variant="h6" sx={{ color: theme.palette.text.white }}>
              Best Plan
            </Typography>
          </Box>
          {planLoading ? (
            <PlanLoaderDashboard />
          ) : planList?.length > 0 ? (
            planList?.map((item, i) => (
              <Box key={i} className="flex justify-between items-center mt-5" sx={{ border: `2px solid ${theme.palette.primary.border}`, padding: "10px", borderRadius: "10px" }}>
                <Box className="flex justify-between items-center gap-2">
                  <Box className="flex justify-between items-center gap-4">
                    <Box
                      className="w-5 text-center h-[45px] rounded-lg flex justify-center items-center"
                      sx={{
                        bgcolor: theme.palette.primary.cardBg,
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                      }}
                    >
                      <Typography variant="p" sx={{ color: theme.palette.text.white }}>
                        {i + 1}
                      </Typography>
                    </Box>
                    <Box className="flex flex-col gap-1">
                      <Typography variant="p" className="text-[var(--text-color)]">
                        Plan : {item?.name}
                      </Typography>
                      <Typography variant="p" sx={{ fontSize: "14px", color: theme.palette.text.white }}>
                        Limit : {item?.limit}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="flex flex-col gap-1">
                  <Typography variant="p" className="text-[var(--text-color)]">
                    Demo Server
                  </Typography>
                  <Typography variant="p" sx={{ fontSize: "14px", color: theme.palette.text.white }}>
                    {item?.demo ? "Yes" : "No"}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                marginTop: "20px",
                color: theme.palette.text.white,
                fontWeight: 500,
                fontSize: "14px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "250px",
                border: `2px solid ${theme.palette.primary.border}`,
                borderRadius: "10px",
              }}
            >
              <CloudOutlined sx={{ fontSize: "40px" }} />
              <Typography>No plan found!</Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
});

export default HeroSection;
