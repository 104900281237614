import { Box, Button, Typography, useTheme } from "@mui/material";
import SearchBar from "components/common/SearchBar";
import Layout from "components/Dashboard/Layout";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setForceUpdateList } from "redux/features/forceUpdate/forceUpdateSlice";
import { setForceUpdateLoader } from "redux/features/loader/loaderSlice";
import { getForceUpdateList } from "server/forceUpdate/forceUpdate";
import CreateForceUpdateModal from "./CreateForceUpdateModal";
import ForceUpdateTable from "./ForceUpdateTable";
import ForceUpdateTypeOption from "./ForceUpdateTypeOption";

const ForceUpdate = memo(() => {
  const theme = useTheme();
  const [createForceUpdateModalOpen, setCreateForceUpdateModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const placeholderText = "Search by version";
  const [filterByType, setFilterByType] = useState("");

  const handleSearchTextChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  const fetchForceUpdateList = async () => {
    try {
      dispatch(setForceUpdateLoader(true));
      const result = await getForceUpdateList();
      if (result?.data?.success) {
        dispatch(setForceUpdateList(result?.data?.data));
        dispatch(setForceUpdateLoader(false));
      } else {
        dispatch(setForceUpdateLoader(false));
      }
    } catch (error) {
      dispatch(setForceUpdateLoader(false));
    }
  };

  useEffect(() => {
    fetchForceUpdateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setFilterByType(event.target.value);
  };

  return (
    <Layout>
      <Box>
        <Box className="flex items-center justify-between my-4">
          <Typography variant="h6" style={{ marginLeft: "15px", color: theme.palette.text.white }}>
            Force Update List
          </Typography>
          <Box sx={{ marginRight: "15px", display: "flex", alignItems: "center", gap: 2 }}>
            <ForceUpdateTypeOption {...{ filterByType, handleChange }} />
            <Box>
              <SearchBar {...{ searchText, handleSearchTextChange, placeholderText }} />
            </Box>
            <Button
              onClick={() => setCreateForceUpdateModalOpen(true)}
              variant="contained"
              sx={{
                background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                color: "white",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
            >
              Force Update Create
            </Button>
          </Box>
        </Box>
        <ForceUpdateTable {...{ searchText, filterByType }} />
      </Box>
      <CreateForceUpdateModal {...{ createForceUpdateModalOpen, setCreateForceUpdateModalOpen }} />
    </Layout>
  );
});

export default ForceUpdate;
