import { CloudOutlined, MoreHoriz } from "@mui/icons-material";
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { Fragment, memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import DeleteUnusedLicenseModal from "./DeleteUnusedLicenseModal";
import UnusedTableActionPopOver from "./UnusedTableActionPopOver";
import UpdateUnusedLicenseModal from "./UpdateUnusedLicenseModal";

const UnUsedLicenseTable = memo(({ searchText }) => {
  const [anchorElUnusedActionPopover, setAnchorElUnusedActionPopover] = useState(null);
  const unUsedActionPopoverOpen = Boolean(anchorElUnusedActionPopover);
  const [openUnusedLicenseDelete, setOpenUnusedLicenseDelete] = useState(false);
  const [openUnusedLicenseUpdate, setOpenUnusedLicenseUpdate] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const loading = useSelector((state) => state.loader.unUsedLicenseLoader);
  const theme = useTheme();
  const unUsedLicense = useSelector((state) => state.license.unUsedLicense);
  const [currentLicense, setCurrentLicense] = useState({});

  const filteredLicenses = useMemo(() => {
    if (!searchText) return unUsedLicense;
    const search = searchText.toLowerCase();
    return unUsedLicense.filter((license) => {
      const licenseKey = license.license_key.toLowerCase();
      return licenseKey.includes(search);
    });
  }, [unUsedLicense, searchText]);

  // Calculate the rows to display based on the current page and rows per page
  const displayedRows = filteredLicenses?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleClickLicenseActionPopover = useCallback((event) => {
    setAnchorElUnusedActionPopover(event.currentTarget);
  }, []);

  const handleCloseUnusedActionPopover = useCallback(() => {
    setAnchorElUnusedActionPopover(null);
  }, []);

  return (
    <Fragment>
      <Box>
        <TableContainer sx={{ height: "calc(100vh - 190px)", width: "100%" }} className="etrsc-scrollbar">
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ background: theme.palette.background.tableHeader }} className="shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]">
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  License Key
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Reg Date
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Expire Date
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center" colSpan={7} sx={{ color: theme.palette.text.white }} className="!font-bold !text-base">
                    <CircularProgress size={28} color="success" />
                  </TableCell>
                </TableRow>
              ) : displayedRows?.length > 0 ? (
                displayedRows?.map((item, i) => (
                  <Fragment key={i}>
                    <TableRow
                      onClick={() => setCurrentLicense(item)}
                      className={`shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]`}
                      sx={
                        {
                          // "&:last-child td, &:last-child th": { border: 0 },
                        }
                      }
                    >
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {item.license_key}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {moment(item?.createdAt).format("YYYY-MM-DD")}
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {moment(item?.expire_date).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.status}
                      </TableCell>
                      <TableCell
                        onClick={handleClickLicenseActionPopover}
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                          textTransform: "capitalize",
                          cursor: "pointer",
                        }}
                      >
                        <MoreHoriz />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow className="!h-5 flex justify-center items-center">
                  <TableCell
                    align="center"
                    colSpan={7}
                    sx={{
                      borderBottom: "none",
                      color: theme.palette.text.white,
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    <CloudOutlined sx={{ fontSize: "40px" }} />
                    <Typography>No data found!</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          <TablePagination
            component="div"
            count={unUsedLicense?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
          />
        </Box>
      </Box>
      <UnusedTableActionPopOver
        {...{ anchorElUnusedActionPopover, unUsedActionPopoverOpen, handleCloseUnusedActionPopover, setOpenUnusedLicenseUpdate, setOpenUnusedLicenseDelete }}
      />
      <UpdateUnusedLicenseModal {...{ openUnusedLicenseUpdate, setOpenUnusedLicenseUpdate, currentLicense }} />
      <DeleteUnusedLicenseModal {...{ openUnusedLicenseDelete, setOpenUnusedLicenseDelete, currentLicense }} />
    </Fragment>
  );
});

export default UnUsedLicenseTable;
