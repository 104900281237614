import { Box, Typography, useTheme } from "@mui/material";
import SearchBar from "components/common/SearchBar";
import Layout from "components/Dashboard/Layout";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBackendResultList } from "redux/features/backendResult/backendResultSlice";
import { setBrokerList } from "redux/features/broker/brokerSlice";
import { setBackendResultLoader } from "redux/features/loader/loaderSlice";
import { getBackendResultList } from "server/backendResult/backendUpdate";
import { getBrokerList } from "server/broker/broker";
import BackendResultTable from "./BackendResultTable";
import CreateBackendResultModal from "./CreateBackendResultModal";

const BackendResult = memo(() => {
  const theme = useTheme();
  const [createBackendResultModalOpen, setCreateBackendResultModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const placeholderText = "Search by version, broker";

  const handleSearchTextChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  const fetchBackendUpdateResultList = async () => {
    try {
      dispatch(setBackendResultLoader(true));
      const result = await getBackendResultList();
      if (result?.data?.success) {
        dispatch(setBackendResultList(result?.data?.data));
        dispatch(setBackendResultLoader(false));
      } else {
        dispatch(setBackendResultLoader(false));
      }
    } catch (error) {
      dispatch(setBackendResultLoader(false));
    }
  };

  const fetchBrokerList = async () => {
    const result = await getBrokerList();
    if (result?.data?.success) {
      dispatch(setBrokerList(result?.data?.data));
    }
  };

  useEffect(() => {
    fetchBackendUpdateResultList();
    fetchBrokerList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Box>
        <Box className="flex items-center justify-between my-4">
          <Typography variant="h6" style={{ marginLeft: "15px", color: theme.palette.text.white }}>
            Backend Result
          </Typography>
          <Box sx={{ marginRight: "15px", display: "flex", alignItems: "center", gap: 2 }}>
            <Box>
              <SearchBar {...{ searchText, handleSearchTextChange, placeholderText }} />
            </Box>
            {/* <Button
              onClick={() => setCreateBackendResultModalOpen(true)}
              variant="contained"
              sx={{
                background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                color: "white",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
            >
              Create Backend Result
            </Button> */}
          </Box>
        </Box>
        <BackendResultTable {...{ searchText }} />
      </Box>
      <CreateBackendResultModal {...{ createBackendResultModalOpen, setCreateBackendResultModalOpen }} />
    </Layout>
  );
});

export default BackendResult;
