import { DarkMode, LightMode } from "@mui/icons-material";
import Menu from "@mui/icons-material/Menu";
import { Avatar, Box, IconButton, Typography, useTheme } from "@mui/material";
import { Fragment, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setThemeMode } from "redux/features/theme/themeSlice";

const Topbar = memo(() => {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeSlice.themeMode);
  const theme = useTheme();

  const changeTheme = useCallback((mode) => {
    if (mode === "dark") {
      dispatch(setThemeMode("dark"));
    } else {
      dispatch(setThemeMode("light"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "64px",
          bgcolor: theme.palette.primary.main,
          borderBottom: `2px solid ${theme.palette.primary.border}`,
        }}
      >
        <Box>
          <Typography
            component={"p"}
            sx={{
              background: "linear-gradient(90deg, #9c90ff, #8cebd9, #cf5af0)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            className="!font-bold !text-lg md:!text-xl lg:!text-2xl !ml-8"
          >
            EdgeTrader Root Service
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 2,
            }}
          >
            {themeMode === "dark" ? (
              <IconButton onClick={() => changeTheme("light")}>
                <LightMode sx={{ color: "var(--icon-color)" }} />
              </IconButton>
            ) : (
              <IconButton onClick={() => changeTheme("dark")}>
                <DarkMode sx={{ color: "var(--icon-color)" }} />
              </IconButton>
            )}
            {/* <img src="/assets/images/icon/man.png" alt="user" width="40" /> */}
            <Avatar sx={{width: 30, height: 30}} alt="user" />
            <IconButton>
              <Menu sx={{ color: "var(--icon-color)" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
});

export default Topbar;
