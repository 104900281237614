import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    licenseListLoader: false,
    extendedLicenseListLoader: false,
    brokerListLoader: false,
    unUsedLicenseLoader: false,
    uploadFileLoader: false,
    planLoader: false,
    forceUpdateLoader: false,
    helpMessageLoader: false,
    backendUpdateLoader: false,
    backendResultLoader: false,
}

export const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLicenseListLoader: (state, action) => {
            state.licenseListLoader = action.payload
        },
        setExtendedLicenseListLoader: (state, action) => {
            state.extendedLicenseListLoader = action.payload
        },
        setBrokerListLoader: (state, action) => {
            state.brokerListLoader = action.payload
        },
        setUnUsedLicenseLoader: (state, action) => {
            state.unUsedLicenseLoader = action.payload
        },
        setUploadFileLoader: (state, action) => {
            state.uploadFileLoader = action.payload
        },
        setPlanLoader: (state, action) => {
            state.planLoader = action.payload
        },
        setForceUpdateLoader: (state, action) => {
            state.forceUpdateLoader = action.payload
        },
        setHelpMessageLoader: (state, action) => {
            state.helpMessageLoader = action.payload
        },
        setBackendUpdateLoader: (state, action) => {
            state.backendUpdateLoader = action.payload
        },
        setBackendResultLoader: (state, action) => {
            state.backendResultLoader = action.payload
        },
    },
})

export const { setExtendedLicenseListLoader, setLicenseListLoader, setBrokerListLoader, setUnUsedLicenseLoader, setUploadFileLoader, setPlanLoader, setForceUpdateLoader, setHelpMessageLoader, setBackendUpdateLoader, setBackendResultLoader } = loaderSlice.actions

export default loaderSlice.reducer