import { DarkMode, LightMode } from "@mui/icons-material";
import { default as Menu, default as MenuIcon } from "@mui/icons-material/Menu";
import { Box, IconButton, Typography } from "@mui/material";
import { Fragment, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleDrawer } from "redux/features/drawer/drawerSlice";
import { setThemeMode } from "redux/features/theme/themeSlice";

const TopbarSm = memo(() => {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeSlice.themeMode);

  const handleDrawerToggle = useCallback(() => {
    dispatch(handleDrawer());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeTheme = (mode) => {
    if (mode === "dark") {
      dispatch(setThemeMode("dark"));
    } else {
      dispatch(setThemeMode("light"));
    }
  };
  return (
    <Fragment>
      <Box className="flex justify-between items-center p-2 box-border">
        <Box className="flex justify-start items-center gap-2">
          <IconButton
            onClick={handleDrawerToggle}
            sx={{
              color: "var(--icon-color)",
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component={"p"}
            sx={{
              background: "linear-gradient(90deg, #9c90ff, #8cebd9, #cf5af0)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            className="!font-bold !text-lg md:!text-xl lg:!text-2xl"
          >
            EdgeTrader Root Service
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 1,
            }}
          >
            {themeMode === "dark" ? (
              <IconButton onClick={() => changeTheme("light")}>
                <LightMode sx={{ color: "var(--icon-color)" }} />
              </IconButton>
            ) : (
              <IconButton onClick={() => changeTheme("dark")}>
                <DarkMode sx={{ color: "var(--icon-color)" }} />
              </IconButton>
            )}

            <IconButton>
              <Menu sx={{ color: "var(--icon-color)" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
});

export default TopbarSm;
