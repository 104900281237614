import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: '"Bai Jamjuree", sans-serif',
    },
  },
});

//For dark theme
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#1F2336",
      white: "#ffffff",
      semiWhite: "#b2b2b4",
      active: "#797FFF",
      icon: "#72799B",
      cardBg: "#25293D",
      border: "#2B3046",
      border2: "#D6DAE4",
      grid: "#2B3046",
      borderColor: '#505f81',
    },
    background: {
      default: "#1F2336",
      main: "#1F2336",
      btnBg: "#313852",
      listItemBg: "#313852",
      inputBg: "#40405647",
      tableHeader: "#313852",
      card1: '#27344c',
      card2: '#1E283B',
      card3: '#1E293B',
    },
    text: {
      white: "#ffffff",
      semiWhite: "#b2b2b4",
      default: "#666D8D",
      placeholder: "#666D8D",
      selectedItem: "#5a5a5ab5",
      activeTab: "#ffffff",
      secondaryText: "#717aa3"
    },

  }
});

//For light theme
export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: "#F7FAFF",
      white: "#0D175D",
      semiWhite: "#333335",
      active: "#797FFF",
      icon: "#72799B",
      cardBg: "#FFFFff",
      border: "#ACACAC29",
      border2: "#38435D",
      grid: "#E5E5E5",
      borderColor: '#d0d9f64d',
    },
    background: {
      default: "#F7FAFF",
      main: "#F7FAFF",
      btnBg: "#313852",
      listItemBg: "#ffffff",
      inputBg: "#dedeef42",
      tableHeader: "#ffffff",
      card1: '#e7eeffbf',
      card2: '#F7FAFF',
      card3: '#afbdf466',
    },
    text: {
      white: "#0D175D",
      semiWhite: "#55555dd9",
      default: "#B7BAD1",
      placeholder: "#B7BAD1",
      selectedItem: "#5a5a5ab5",
      activeTab: "#797FFF",
      secondaryText: "#3a3a4c"
    },

  }
});
