import { CloudOutlined, MoreHoriz } from "@mui/icons-material";
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from "@mui/material";
import { Fragment, memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import BackendUpdateActionPopover from "./BackendUpdateActionPopover";
import DeleteBackendUpdateModal from "./DeleteBackendUpdateModal";
import UpdateBackendUpdateModal from "./UpdateBackendUpdateModal";

const BackendUpdateTable = memo(({ searchText }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const loading = useSelector((state) => state.loader.backendUpdateLoader);
  const theme = useTheme();
  const backendUpdateList = useSelector((state) => state.backendUpdate.backendUpdateList);
  const [currentBackendUpdate, setCurrentBackendUpdate] = useState({});
  const [updateBackendUpdateModalOpen, setUpdateBackendUpdateModalOpen] = useState(false);
  const [deleteBackendUpdateModalOpen, setDeleteBackendUpdateModalOpen] = useState(false);
  const [backendUpdateActionPopover, setBackendUpdateActionPopover] = useState(null);
  const forceBackendActionPopoverOpen = Boolean(backendUpdateActionPopover);


  const handleClickBackendUpdateActionPopover = useCallback((event) => {
    setBackendUpdateActionPopover(event.currentTarget);
  }, []);

  const handleCloseBackendUpdateActionPopover = useCallback(() => {
    setBackendUpdateActionPopover(null);
  }, []);

  const filteredBackendUpdate = useMemo(() => {
    if (!searchText) return backendUpdateList;
    const search = searchText.toLowerCase();
    return backendUpdateList.filter((item) => {
      const version = item.version.toLowerCase();
      const version_code = item.version_code.toLowerCase();

      return version.includes(search) || version_code.includes(search);
    });
  }, [backendUpdateList, searchText]);

  // Calculate the rows to display based on the current page and rows per page
  const displayedRows = filteredBackendUpdate?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  return (
    <Fragment>
      <Box>
        <TableContainer sx={{ height: "calc(100vh - 190px)", width: "100%" }} className="etrsc-scrollbar">
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ background: theme.palette.background.tableHeader }} className="shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]">
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Version
                </TableCell>

                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Version Code
                </TableCell>

                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  File Name
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Broker ID
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center" colSpan={7} sx={{ color: theme.palette.text.white }} className="!font-bold !text-base">
                    <CircularProgress size={28} color="success" />
                  </TableCell>
                </TableRow>
              ) : displayedRows?.length > 0 ? (
                displayedRows?.map((item, i) => (
                  <Fragment key={i}>
                    <TableRow onClick={() => setCurrentBackendUpdate(item)} className={`shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]`}>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {item?.version}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {item?.version_code}
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {item?.file_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {item?.broker_id}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                      >
                        {item?.status}
                      </TableCell>

                      <TableCell
                        onClick={(event) => handleClickBackendUpdateActionPopover(event)}
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      >
                        <MoreHoriz />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow className="!h-5 flex justify-center items-center">
                  <TableCell
                    align="center"
                    colSpan={7}
                    sx={{
                      borderBottom: "none",
                      color: theme.palette.text.white,
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    <CloudOutlined sx={{ fontSize: "40px" }} />
                    <Typography>No data found!</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          <TablePagination
            component="div"
            count={backendUpdateList?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
          />
        </Box>
      </Box>
      <BackendUpdateActionPopover
        {...{ backendUpdateActionPopover, forceBackendActionPopoverOpen, handleCloseBackendUpdateActionPopover, setUpdateBackendUpdateModalOpen, setDeleteBackendUpdateModalOpen, currentBackendUpdate }}
      />
      <UpdateBackendUpdateModal {...{ updateBackendUpdateModalOpen, setUpdateBackendUpdateModalOpen, currentBackendUpdate }} />
      <DeleteBackendUpdateModal {...{ deleteBackendUpdateModalOpen, setDeleteBackendUpdateModalOpen, currentBackendUpdate }} />
    </Fragment>
  );
});

export default BackendUpdateTable;
