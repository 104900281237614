import api from "api";
import { getAuthToken } from "server/auth/auth";

export async function uploadFile(data) {
    try {
        return await api.post("/file/upload", data, {
            headers: {
                Authorization: getAuthToken(),
                "Content-Type": "multipart/form-data",
            },
        });
    } catch (error) {
        console.log("🚀 ~ uploadFile ~ error:", error)
    }
}

export async function uploadFileList() {
    try {
        return await api.get("/file/list", {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ uploadFileList ~ error:", error)
    }
}

export async function uploadFileDelete(id) {
    try {
        return await api.delete(`/file/${id}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ uploadFileDelete ~ error:", error)
    }
}