import api from "api";
import { getAuthToken } from "server/auth/auth";
export async function getBackendResultList() {
    try {
        return await api.get("backend-update-result", {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getBackendResultList ~ error:", error)
    }
}

export async function createBackendResult(data) {
    try {
        return await api.post("backend-update-result", data, {
            headers: {
                Authorization: getAuthToken(),
                "Content-Type": "multipart/form-data",
            },
        });
    } catch (error) {
        console.log("🚀 ~ createBackendResult ~ error:", error)
    }
}


// export async function updateBackendResult(data) {
//     try {
//         return await api.put("backend-update-result", data, {
//             headers: {
//                 Authorization: getAuthToken(),
//                 "Content-Type": "multipart/form-data",
//             },
//         });
//     } catch (error) {
//         console.log("🚀 ~ updateBackendResult ~ error:", error)
//     }
// }

export async function deleteBackendResult(id) {
    try {
        return await api.delete(`backend-update-result/${id}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ deleteBackendResult ~ error:", error)
    }
}
