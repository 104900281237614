import { Box, Typography, useTheme } from "@mui/material";
import SearchBar from "components/common/SearchBar";
import Layout from "components/Dashboard/Layout";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBrokerList } from "redux/features/broker/brokerSlice";
import { setUnusedLicenseList } from "redux/features/license/licenseSlice";
import { setUnUsedLicenseLoader } from "redux/features/loader/loaderSlice";
import { getBrokerList } from "server/broker/broker";
import { getUnusedLicenseList } from "server/license/license";
import UnUsedLicenseTable from "./UnUsedLicenseTable";

const UnUsedLicense = memo(() => {
  const [searchText, setSearchText] = useState("");
  const placeholderText = "Search by license key";
  const theme = useTheme();
  const dispatch = useDispatch();
  const fetchExtendedLicenseList = async () => {
    dispatch(setUnUsedLicenseLoader(true));
    const result = await getUnusedLicenseList();
    if (result?.data?.status) {
      dispatch(setUnusedLicenseList(result?.data?.data));
      dispatch(setUnUsedLicenseLoader(false));
    } else {
      dispatch(setUnUsedLicenseLoader(false));
    }
  };

  const fetchBrokerList = async () => {
    const result = await getBrokerList();
    if (result?.data?.success) {
      dispatch(setBrokerList(result?.data?.data));
    }
  };

  const handleSearchTextChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  useEffect(() => {
    fetchExtendedLicenseList();
    fetchBrokerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Box>
        <Box className="flex items-center justify-between my-4">
          <Typography variant="h6" style={{ marginLeft: "15px", color: theme.palette.text.white }}>
            Unused License
          </Typography>
          <Box className="mr-3">
            <SearchBar {...{ searchText, handleSearchTextChange, placeholderText }} />
          </Box>
        </Box>
        <UnUsedLicenseTable {...{ searchText }} />
      </Box>
    </Layout>
  );
});

export default UnUsedLicense;
