import { CloudOutlined, MoreHoriz } from "@mui/icons-material";
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from "@mui/material";
import { Fragment, memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import DeleteForceUpdateModal from "./DeleteForceUpdateModal";
import ForceUpdateActionPopover from "./ForceUpdateActionPopover";
import UpdateForceUpdateModal from "./UpdateForceUpdateModal";

const ForceUpdateTable = memo(({ searchText, filterByType }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const loading = useSelector((state) => state.loader.forceUpdateLoader);
  const theme = useTheme();
  const unsortedForceUpdateList = useSelector((state) => state.force.forceUpdateList);
  const forceUpdateList = useMemo(() => {
    return [...unsortedForceUpdateList].sort((a, b) => parseInt(b.version) - parseInt(a.version));
  }, [unsortedForceUpdateList]);

  const [currentForceUpdate, setCurrentForceUpdate] = useState({});
  const [updateForceUpdateModalOpen, setUpdateForceUpdateModalOpen] = useState(false);
  const [deleteForceUpdateModalOpen, setDeleteForceUpdateModalOpen] = useState(false);
  const [forceUpdateActionPopover, setForceUpdateActionPopover] = useState(null);
  const forceUpdateActionPopoverOpen = Boolean(forceUpdateActionPopover);

  const handleClickForceUpdateActionPopover = useCallback((event) => {
    setForceUpdateActionPopover(event.currentTarget);
  }, []);

  const handleCloseForceUpdateActionPopover = useCallback(() => {
    setForceUpdateActionPopover(null);
  }, []);

  const filteredForceUpdateList = useMemo(() => {
    let list = [...forceUpdateList];
    if (searchText) {
      const search = searchText.toLowerCase();
      list = list.filter((item) => {
        const version = item.version.toLowerCase();
        return version.includes(search);
      });
    }
    if (filterByType) {
      list = list.filter((item) => item.type === filterByType);
    }
    return list;
  }, [forceUpdateList, searchText, filterByType]);

  // Calculate the rows to display based on the current page and rows per page
  const displayedRows = filteredForceUpdateList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  return (
    <Fragment>
      <Box>
        <TableContainer sx={{ height: "calc(100vh - 190px)", width: "100%" }} className="etrsc-scrollbar">
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ background: theme.palette.background.tableHeader }} className="shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]">
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Version
                </TableCell>

                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Type
                </TableCell>

                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Force
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center" colSpan={7} sx={{ color: theme.palette.text.white }} className="!font-bold !text-base">
                    <CircularProgress size={28} color="success" />
                  </TableCell>
                </TableRow>
              ) : displayedRows?.length > 0 ? (
                displayedRows?.map((item, i) => (
                  <Fragment key={i}>
                    <TableRow onClick={() => setCurrentForceUpdate(item)} className={`shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]`}>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {item?.version}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {item?.type === "android" ? "Android" : item?.type === "ios" ? "iOS" : item.type === "windows" ? "Windows" : "macOS"}
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                      >
                        {item?.force ? "true" : "false"}
                      </TableCell>

                      <TableCell
                        onClick={(event) => handleClickForceUpdateActionPopover(event)}
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      >
                        <MoreHoriz />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow className="!h-5 flex justify-center items-center">
                  <TableCell
                    align="center"
                    colSpan={7}
                    sx={{
                      borderBottom: "none",
                      color: theme.palette.text.white,
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    <CloudOutlined sx={{ fontSize: "40px" }} />
                    <Typography>No data found!</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          <TablePagination
            component="div"
            count={forceUpdateList?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
          />
        </Box>
      </Box>
      <ForceUpdateActionPopover
        {...{ forceUpdateActionPopover, forceUpdateActionPopoverOpen, handleCloseForceUpdateActionPopover, setUpdateForceUpdateModalOpen, setDeleteForceUpdateModalOpen }}
      />
      <UpdateForceUpdateModal {...{ updateForceUpdateModalOpen, setUpdateForceUpdateModalOpen, currentForceUpdate }} />
      <DeleteForceUpdateModal {...{ deleteForceUpdateModalOpen, setDeleteForceUpdateModalOpen, currentForceUpdate }} />
    </Fragment>
  );
});

export default ForceUpdateTable;
