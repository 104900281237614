import api from "api";
import { getAuthToken } from "server/auth/auth";
export async function getHelpMessage() {
    try {
        return await api.get("/help-center", {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getHelpMessage ~ error:", error)
    }
}


export async function updateHelpMessage(data) {
    try {
        return await api.put("/help-center", data, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ updateHelpMessage ~ error:", error)
    }
}

export async function deleteHelpMessage(id) {
    try {
        return await api.delete(`/help-center/${id}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ deleteHelpMessage ~ error:", error)
    }
}
