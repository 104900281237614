import { Box, Typography, useTheme } from "@mui/material";
import Layout from "components/Dashboard/Layout";
import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUploadFileLoader } from "redux/features/loader/loaderSlice";
import { setUploadFileList } from "redux/features/upload/uploadSlice";
import { uploadFileList } from "server/upload/uploadFile";
import UploadFileListTable from "./UploadFileListTable";

const UploadFileList = memo(() => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const getUploadFileList = async () => {
    dispatch(setUploadFileLoader(true));
    const result = await uploadFileList();
    if (result?.data?.success) {
      dispatch(setUploadFileList(result?.data?.data));
      dispatch(setUploadFileLoader(false));
    } else {
      dispatch(setUploadFileLoader(false));
    }
  };

  useEffect(() => {
    getUploadFileList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Box>
        <Box className="flex items-center justify-between my-4">
          <Typography variant="h6" style={{ marginLeft: "15px", color: theme.palette.text.white }}>
            Upload File List
          </Typography>
        </Box>
        <UploadFileListTable />
      </Box>
    </Layout>
  );
});

export default UploadFileList;
