import { Avatar, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from "@mui/material";
import moment from "moment";
import { memo } from "react";

const BrokerDetailsTable = memo(({ currentBroker }) => {
  const theme = useTheme();
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              background: theme.palette.background.tableHeader,
            }}
          >
            Phone
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              background: theme.palette.background.tableHeader,
            }}
          >
            Country
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              background: theme.palette.background.tableHeader,
            }}
          >
            State
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              background: theme.palette.background.tableHeader,
            }}
          >
            ZIP
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              background: theme.palette.background.tableHeader,
            }}
          >
            Address
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              background: theme.palette.background.tableHeader,
            }}
          >
            Reg Date
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              background: theme.palette.background.tableHeader,
            }}
          >
            Exp Date
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              background: theme.palette.background.tableHeader,
            }}
          >
            Company URL
          </TableCell>

          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              background: theme.palette.background.tableHeader,
            }}
          >
            Company Phone
          </TableCell>

          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              background: theme.palette.background.tableHeader,
            }}
          >
            Company Email
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              background: theme.palette.background.tableHeader,
            }}
          >
            Company Address
          </TableCell>

          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              background: theme.palette.background.tableHeader,
            }}
          >
            Server
          </TableCell>

          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              background: theme.palette.background.tableHeader,
            }}
          >
            Logo
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            {currentBroker?.phone}
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              textTransform: "capitalize",
            }}
          >
            {currentBroker?.country}
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              textTransform: "capitalize",
            }}
          >
            {currentBroker?.state}
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              textTransform: "capitalize",
            }}
          >
            {currentBroker?.zip}
          </TableCell>
          <TableCell
            title={currentBroker?.address}
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            {currentBroker?.address?.slice(0, 15)}...
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            {moment(currentBroker.createdAt).format("YYYY-MM-DD")}
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            {moment(currentBroker?.expire_date).format("YYYY-MM-DD")}
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            <a style={{ color: theme.palette.text.white, textDecoration: "none" }} href={currentBroker?.company_url} target="_blank" rel="noreferrer">
              {currentBroker?.company_url}
            </a>
          </TableCell>

          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              textTransform: "capitalize",
            }}
          >
            {currentBroker.company_phone}
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            {currentBroker.company_email}
          </TableCell>
          <TableCell
            title={currentBroker?.company_address}
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            {currentBroker?.company_address?.slice(0, 15)}...
          </TableCell>
          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            {/* <a style={{ color: theme.palette.text.white, textDecoration: "none" }} href={currentBroker?.server} target="_blank" rel="noreferrer"> */}
            {currentBroker?.server}
            {/* </a> */}
          </TableCell>

          <TableCell
            sx={{
              borderBottom: `1px solid ${theme.palette.primary.border}`,
              color: theme.palette.text.white,
              fontWeight: 500,
              fontSize: "14px",
              textTransform: "capitalize",
            }}
          >
            <Avatar src={currentBroker?.logo_url} alt={currentBroker?.name} variant="rounded" sx={{ width: 25, height: 25 }} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
});

export default BrokerDetailsTable;
