import { Grid, Skeleton, Typography } from "@mui/material";
import { memo } from "react";

const PlanLoaderDashboard = memo(() => {
  return (
    <Grid container sx={{ justifyContent: "center" }}>
      <Grid item xs={12}>
        <Typography component="div" variant="h3">
          <Skeleton />
        </Typography>
        <Typography component="div" variant="h3">
          <Skeleton />
        </Typography>
        <Typography component="div" variant="h3">
          <Skeleton />
        </Typography>
        <Typography component="div" variant="h3">
          <Skeleton />
        </Typography>
        <Typography component="div" variant="h3">
          <Skeleton />
        </Typography>
      </Grid>
    </Grid>
  );
});

export default PlanLoaderDashboard;
