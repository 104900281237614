import { Box } from "@mui/material";
import HeroSection from "components/Dashboard/DashboardContent/HeroSection";
import ResultTable from "components/Dashboard/DashboardContent/ResultTable";
import Layout from "components/Dashboard/Layout";
import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBrokerList } from "redux/features/broker/brokerSlice";
import { setForceUpdateList } from "redux/features/forceUpdate/forceUpdateSlice";
import { setBrokerListLoader, setForceUpdateLoader, setPlanLoader } from "redux/features/loader/loaderSlice";
import { setPlanList } from "redux/features/plan/planSlice";
import { getBrokerList } from "server/broker/broker";
import { getForceUpdateList } from "server/forceUpdate/forceUpdate";
import { getPlanList } from "server/plan/plan";

const Dashboard = memo(() => {
  const dispatch = useDispatch();

  const fetchBrokerList = async () => {
    try {
      dispatch(setBrokerListLoader(true));
      const result = await getBrokerList();
      if (result?.data?.success) {
        dispatch(setBrokerList(result?.data?.data));
        dispatch(setBrokerListLoader(false));
      } else {
        dispatch(setBrokerListLoader(false));
      }
    } catch (error) {
      dispatch(setBrokerListLoader(false));
    }
  };
  const fetchPlanList = async () => {
    try {
      dispatch(setPlanLoader(true));
      const result = await getPlanList();
      if (result?.data?.success) {
        dispatch(setPlanList(result?.data?.data));
        dispatch(setPlanLoader(false));
      } else {
        dispatch(setPlanLoader(false));
      }
    } catch (error) {
      dispatch(setPlanLoader(false));
    }
  };
  const fetchForceUpdateList = async () => {
    try {
      dispatch(setForceUpdateLoader(true));
      const result = await getForceUpdateList();
      if (result?.data?.success) {
        dispatch(setForceUpdateList(result?.data?.data));
        dispatch(setForceUpdateLoader(false));
      } else {
        dispatch(setForceUpdateLoader(false));
      }
    } catch (error) {
      dispatch(setForceUpdateLoader(false));
    }
  };

  useEffect(() => {
    fetchBrokerList();
    fetchPlanList();
    fetchForceUpdateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Box sx={{ padding: "19.5px 30px", boxSizing: "border-box", height: "calc(100vh - 68px)", overflowY: "auto" }} className="etrsc-scrollbar">
        <Box>
          <HeroSection />
        </Box>
        <ResultTable />
      </Box>
    </Layout>
  );
});

export default Dashboard;
