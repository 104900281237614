import api from "api";
import { getAuthToken } from "server/auth/auth";

export async function getLicenseList() {
    try {
        return await api.get("/license", {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getLicenseList ~ error:", error)
    }
}

export async function licenseUpdate({ id, expire_date, broker_id }) {
    try {
        return await api.put("/license", { id, expire_date, ...(broker_id && { broker_id }) }, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ licenseUpdate ~ error:", error)
    }
}

export async function licenseValidate({ license_key }) {
    try {
        return await api.post("/license/validate", { license_key }, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ licenseValidate ~ error:", error)
    }
}

export async function licenseDelete(id) {
    try {
        return await api.delete(`/license/${id}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ licenseDelete ~ error:", error)
    }
}

export async function getExtendedLicenseList() {
    try {
        return await api.get("/license/extended-list", {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getExtendedLicenseList ~ error:", error)
    }
}

export async function getUnusedLicenseList() {
    try {
        return await api.get("/license/unused-license", {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getUnusedLicenseList ~ error:", error)
    }
}

export async function createSlice({ broker_id, expire_date, status }) {
    try {
        return await api.post("/license", { ...(broker_id && { broker_id }), expire_date, status }, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ createSlice ~ error:", error)
    }
}