import { ContentCopy, Delete, Edit } from "@mui/icons-material";
import { ListItemIcon, Menu, MenuItem, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { Fragment, memo, useCallback } from "react";

const BackendUpdateActionPopover = memo(
  ({ backendUpdateActionPopover, forceBackendActionPopoverOpen, handleCloseBackendUpdateActionPopover, setUpdateBackendUpdateModalOpen, setDeleteBackendUpdateModalOpen, currentBackendUpdate }) => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const handleUpdateBackendUpdate = useCallback(() => {
      setUpdateBackendUpdateModalOpen(true);
      handleCloseBackendUpdateActionPopover();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDeleteBackendUpdate = useCallback(() => {
      setDeleteBackendUpdateModalOpen(true);
      handleCloseBackendUpdateActionPopover();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCopy = useCallback((text) => {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          enqueueSnackbar("File url copied to clipboard!", { variant: "success" });
        })
        .catch((error) => {
          enqueueSnackbar("Failed to copy file url. Please try again.", { variant: "error" });
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Fragment>
        <Menu
          anchorEl={backendUpdateActionPopover}
          open={forceBackendActionPopoverOpen}
          onClose={handleCloseBackendUpdateActionPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPaper-root": {
              overflow: "hidden",
              backgroundColor: theme.palette.background.main,
            },
          }}
        >
          <MenuItem sx={{ fontSize: "14px" }} onClick={handleUpdateBackendUpdate}>
            <ListItemIcon>
              <Edit sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            Edit
          </MenuItem>

          <MenuItem sx={{ fontSize: "14px" }} onClick={handleDeleteBackendUpdate}>
            <ListItemIcon>
              <Delete sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            Delete
          </MenuItem>
          <MenuItem sx={{ fontSize: "14px" }} onClick={() => handleCopy(currentBackendUpdate?.file_url)}>
            <ListItemIcon>
              <ContentCopy
                titleAccess="Copy File URL"
                sx={{ cursor: "pointer", marginLeft: "4px", fontSize: "20px", color: theme.palette.text.white, opacity: 0.7, "&:active": { opacity: 1 } }}
              />
            </ListItemIcon>
            Copy File URL
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }
);

export default BackendUpdateActionPopover;
