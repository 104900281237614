import { Box, CircularProgress, useTheme } from "@mui/material";
import React, { memo } from "react";

const Loader = () => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw", background: theme.palette.background.default }}>
      <CircularProgress color="success" />
    </Box>
  );
};

export default memo(Loader);
