import { useTheme } from "@emotion/react";
import { CloudOutlined } from "@mui/icons-material";
import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment } from "react";
import { useSelector } from "react-redux";

const ResultTable = () => {
  const theme = useTheme();
  const loading = useSelector((state) => state.loader.brokerListLoader);
  const unSortedBrokerList = useSelector((state) => state.broker.brokerList);
  const brokerList = [...unSortedBrokerList].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <>
      <Typography variant="h6" style={{ marginTop: "50px", marginLeft: "15px", color: theme.palette.text.white }}>
        Latest Broker
      </Typography>
      <Box>
        <TableContainer sx={{ width: "100%" }} className="etrsc-scrollbar">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    color: "var(--text-color)",
                    borderBottom: `2px solid ${theme.palette.primary.border}`,
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--text-color)",
                    borderBottom: `2px solid ${theme.palette.primary.border}`,
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--text-color)",
                    borderBottom: `2px solid ${theme.palette.primary.border}`,
                  }}
                >
                  Phone
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--text-color)",
                    borderBottom: `2px solid ${theme.palette.primary.border}`,
                  }}
                >
                  Company Name
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--text-color)",
                    borderBottom: `2px solid ${theme.palette.primary.border}`,
                  }}
                >
                  Type
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--text-color)",
                    borderBottom: `2px solid ${theme.palette.primary.border}`,
                  }}
                >
                  Broker Type
                </TableCell>
                <TableCell
                  sx={{
                    color: "var(--text-color)",
                    borderBottom: `2px solid ${theme.palette.primary.border}`,
                  }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow
                  sx={{
                    ".MuiTableCell-root": { borderBottom: `1px solid ${theme.palette.primary.border}` },
                  }}
                >
                  <TableCell
                    align="center"
                    colSpan={11}
                    sx={{ color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-black-1)" }}
                    className="!font-bold !text-base"
                  >
                    <CircularProgress size={28} color="success" />
                  </TableCell>
                </TableRow>
              ) : brokerList.length === 0 ? (
                <TableRow className="!h-5 flex justify-center items-center">
                  <TableCell
                    align="center"
                    colSpan={11}
                    sx={{
                      borderBottom: "none",
                      color: theme.palette.text.white,
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    <CloudOutlined sx={{ fontSize: "40px" }} />
                    <Typography>No data found!</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                brokerList?.slice(0, 3)?.map((item, i) => (
                  <Fragment key={i}>
                    <TableRow
                      className={`shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]`}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: "var(--text-color)",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          textTransform: "capitalize",
                        }}
                      >
                        <Avatar src={item?.logo_url} alt={item?.name} variant="rounded" sx={{ width: 50, height: 50 }} />
                        {item.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: "var(--text-color)",
                        }}
                      >
                        {item.email}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: "var(--text-color)",
                        }}
                      >
                        {item.phone}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: "var(--text-color)",
                        }}
                      >
                        {item.company_name}
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: "var(--text-color)",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.type}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: "var(--text-color)",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.broker_type}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: "var(--text-color)",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.status}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default ResultTable;
