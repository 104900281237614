import api from "api";
export async function login(data) {
    try {
        return await api.post("/user/login", data);
    } catch (error) {
        console.log("🚀 ~ login ~ error:", error)
    }
}

export const getAuthToken = () => {
    try {
        return localStorage.getItem("auth-token") ? `Bearer ${localStorage.getItem("auth-token")}` : null;
    } catch (error) {
        console.log("🚀 ~ getAuthToken ~ error:", error)
    }
};

export async function getRefreshToken(refreshToken) {
    try {
        return await api.post("/user/refresh-token", { refreshToken: refreshToken });
    } catch (error) {
        console.log("🚀 ~ getRefreshToken ~ error:", error)
    }
}