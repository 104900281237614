import { CloudOutlined, ContentCopy, KeyboardArrowDown, KeyboardArrowUp, MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Chip,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Fragment, memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import LicenseTableActionPopOver from "./LicenseTableActionPopOver";
import UpdateLicenseModal from "./UpdateLicenseModal";

const LicenseTable = memo(({ searchText }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentRowLicense, setCurrentRowLicense] = useState({});
  const [currentLicense, setCurrentLicense] = useState({});
  const [updateLicenseModalOpen, setUpdateLicenseModalOpen] = useState(false);
  const loading = useSelector((state) => state.loader.licenseListLoader);
  const theme = useTheme();
  const licenseList = useSelector((state) => state.license.licenseList);
  const [anchorElLicenseActionPopover, setAnchorElLicenseActionPopover] = useState(null);
  const licenseActionPopoverOpen = Boolean(anchorElLicenseActionPopover);

  const handleClickLicenseActionPopover = useCallback((event) => {
    setAnchorElLicenseActionPopover(event.currentTarget);
  }, []);

  const handleCloseLicenseActionPopover = useCallback(() => {
    setAnchorElLicenseActionPopover(null);
  }, []);

  const handleToggleCollapse = useCallback((index, license) => {
    setCurrentLicense(license);
    setExpandedRowIndex((prevIndex) => (prevIndex === index ? null : index));
  }, []);

  const filteredLicenses = useMemo(() => {
    if (!searchText) return licenseList;
    const search = searchText.toLowerCase();
    return licenseList.filter((license) => {
      const licenseKey = license.license_key.toLowerCase();
      return licenseKey.includes(search);
    });
  }, [licenseList, searchText]);

  // Calculate the rows to display based on the current page and rows per page
  const displayedRows = filteredLicenses?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleCopy = useCallback((text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar("License copied to clipboard!", { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar("Failed to copy license. Please try again.", { variant: "error" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      <Box>
        <TableContainer sx={{ height: "calc(100vh - 190px)", width: "100%" }} className="etrsc-scrollbar">
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ background: theme.palette.background.tableHeader }} className="shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]">
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Broker Name
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  License Key
                </TableCell>
                <TableCell
                  sx={{
                    background: theme.palette.background.tableHeader,
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Registration Date
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Expire Date
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Status
                </TableCell>

                <TableCell
                  sx={{
                    borderBottom: "none",
                    background: theme.palette.background.tableHeader,
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center" colSpan={7} sx={{ color: theme.palette.text.white }} className="!font-bold !text-base">
                    <CircularProgress size={28} color="success" />
                  </TableCell>
                </TableRow>
              ) : displayedRows?.length > 0 ? (
                displayedRows?.map((item, i) => (
                  <Fragment key={i}>
                    <TableRow
                      onClick={() => setCurrentRowLicense(item)}
                      className={`shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]`}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        sx={{
                          borderBottom: `none`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        <IconButton aria-label="expand row" size="small" onClick={() => handleToggleCollapse(i, item)}>
                          {expandedRowIndex === i ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `none`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {item?.broker?.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `none`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          {item.license_key}
                          <ContentCopy sx={{ fontSize: "18px", cursor: "pointer", opacity: 0.7, "&:active": { opacity: 1 } }} onClick={() => handleCopy(item?.license_key)} />
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `none`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {moment(item?.createdAt).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `none`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          {moment(item?.expire_date).format("YYYY-MM-DD")}
                          <Chip
                            label={moment(item?.expire_date).isBefore(moment(), "day") ? "Expired" : "Valid"}
                            size="small"
                            color={moment(item?.expire_date).isBefore(moment(), "day") ? "error" : "success"}
                            sx={{ color: "white" }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `none`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.status}
                      </TableCell>

                      <TableCell
                        onClick={handleClickLicenseActionPopover}
                        sx={{
                          borderBottom: `none`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      >
                        <MoreHoriz />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={10}
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                          padding: 0,
                        }}
                      >
                        <Collapse in={expandedRowIndex === i} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1 }}>
                            <Typography
                              style={{
                                marginLeft: "15px",
                                marginBottom: "10px",
                                color: theme.palette.text.white,
                                borderBottom: `2px solid ${theme.palette.text.white}`,
                                width: "200px",
                                paddingBottom: "4px",
                              }}
                            >
                              Broker Details
                            </Typography>
                            <Typography style={{ marginLeft: "15px", marginBottom: "15px", color: theme.palette.text.white, fontSize: "14px" }}>
                              Broker ID : {currentLicense?.broker?.broker_id}
                            </Typography>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      background: theme.palette.background.tableHeader,
                                    }}
                                  >
                                    Name
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      background: theme.palette.background.tableHeader,
                                    }}
                                  >
                                    Email
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      background: theme.palette.background.tableHeader,
                                    }}
                                  >
                                    Phone
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      background: theme.palette.background.tableHeader,
                                    }}
                                  >
                                    Country
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      background: theme.palette.background.tableHeader,
                                    }}
                                  >
                                    Registration Date
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      background: theme.palette.background.tableHeader,
                                    }}
                                  >
                                    Company Name
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      background: theme.palette.background.tableHeader,
                                    }}
                                  >
                                    Status
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      background: theme.palette.background.tableHeader,
                                    }}
                                  >
                                    Type
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow
                                  key={i}
                                  sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                    }}
                                  >
                                    {currentLicense?.broker?.name}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                    }}
                                  >
                                    {currentLicense?.broker?.email}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                    }}
                                  >
                                    {currentLicense?.broker?.phone}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                    }}
                                  >
                                    {currentLicense?.broker?.country}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                    }}
                                  >
                                    {moment(currentLicense?.broker?.createdAt).format("YYYY-MM-DD")}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                    }}
                                  >
                                    {currentLicense?.broker?.company_name}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {currentLicense?.broker?.status}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      borderBottom: `1px solid ${theme.palette.primary.border}`,
                                      color: theme.palette.text.white,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {currentLicense?.broker?.type}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow className="!h-5 flex justify-center items-center">
                  <TableCell
                    align="center"
                    colSpan={7}
                    sx={{
                      borderBottom: "none",
                      color: theme.palette.text.white,
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    <CloudOutlined sx={{ fontSize: "40px" }} />
                    <Typography>No data found!</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          <TablePagination
            component="div"
            count={licenseList?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
          />
        </Box>
      </Box>
      <UpdateLicenseModal {...{ updateLicenseModalOpen, setUpdateLicenseModalOpen, currentRowLicense }} />
      <LicenseTableActionPopOver {...{ anchorElLicenseActionPopover, licenseActionPopoverOpen, handleCloseLicenseActionPopover, setUpdateLicenseModalOpen, currentRowLicense }} />
    </Fragment>
  );
});

export default LicenseTable;
