import { FormControl, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { memo } from "react";

const ForceUpdateTypeOption = memo(({ filterByType, handleChange }) => {
  const theme = useTheme();

  return (
    <FormControl
      sx={{
        width: "150px",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "1px solid var(--primary-color)",
          },
          "&:hover fieldset": {
            border: "1px solid var(--secondary-color)",
          },
          "&.Mui-focused fieldset": {
            border: `1px solid var(--secondary-color)`,
          },
        },
        "& .MuiInputLabel-root": {
          color: theme.palette.text.white,
          "&.Mui-focused": {
            color: theme.palette.text.white,
          },
        },
      }}
      size="small"
    >
      <InputLabel id="type">Type</InputLabel>
      <Select
        MenuProps={{
          PaperProps: {
            style: {
              background: theme.palette.background.main,
            },
          },
        }}
        labelId="type"
        name="type"
        id="type"
        label="Type"
        value={filterByType}
        onChange={handleChange}
      >
        <MenuItem value="" disabled key={"first"}>
          Select type
        </MenuItem>
        <MenuItem value="android">Android</MenuItem>
        <MenuItem value="windows">Windows</MenuItem>
        <MenuItem value="macos">macOS</MenuItem>
        <MenuItem value="ios">iOS</MenuItem>
        {filterByType && filterByType !== "" && <MenuItem value="">Reset filter type</MenuItem>}
      </Select>
    </FormControl>
  );
});

export default ForceUpdateTypeOption;
