import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    brokerList: [],
}

export const brokerSlice = createSlice({
    name: 'broker',
    initialState,
    reducers: {
        setBrokerList: (state, action) => {
            state.brokerList = action.payload
        },
        addBrokerList: (state, action) => {
            state.brokerList.push(action.payload)
        },
        updateBrokerList: (state, action) => {
            const index = state.brokerList.findIndex(broker => broker._id === action.payload._id)
            if (index !== -1) {
                state.brokerList[index] = {
                    ...state.brokerList[index],
                    ...action.payload,
                }
            }
        },
        removeBroker: (state, action) => {
            state.brokerList = state.brokerList.filter(broker => broker._id !== action.payload)
        },
    },
})

export const { setBrokerList, removeBroker, addBrokerList, updateBrokerList } = brokerSlice.actions

export default brokerSlice.reducer