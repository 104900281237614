import { useTheme } from "@emotion/react";
import { HighlightOff } from "@mui/icons-material";
import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeLicenseList } from "redux/features/license/licenseSlice";
import { licenseUpdate } from "server/license/license";
import * as yup from "yup";

const validationSchema = yup.object({
  expire_date: yup.string("Select expire date").required("Expire date is required"),
});

const UpdateUnusedLicenseModal = memo(({ openUnusedLicenseUpdate, setOpenUnusedLicenseUpdate, currentLicense = {} }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const brokerList = useSelector((state) => state.broker.brokerList);

  const formik = useFormik({
    initialValues: {
      expire_date: dayjs(currentLicense?.expire_date) || null,
      broker: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      const expire_date = dayjs(values.expire_date).format("YYYY-MM-DD");
      const result = await licenseUpdate({ id: currentLicense?._id, expire_date: expire_date, broker_id: values?.broker });
      if (result?.data?.success) {
        dispatch(removeLicenseList(currentLicense?._id));
        enqueueSnackbar(result?.data?.message, { variant: "success" });
        setOpenUnusedLicenseUpdate(false);
        setLoading(false);
        formik.resetForm();
      } else {
        setLoading(false);
        enqueueSnackbar(result?.data?.message, { variant: "error" });
      }
    },
  });

  return (
    <Modal open={openUnusedLicenseUpdate} onClose={() => setOpenUnusedLicenseUpdate(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          backgroundColor: theme.palette.background.main,
          borderRadius: "5px",
          padding: "25px",
          outline: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={4} columnSpacing={3} alignItems={"center"}>
            <Grid item xs={6}>
              <Typography sx={{ color: theme.palette.text.white }} className="!capitalize">
                Update License
              </Typography>
            </Grid>
            <Grid item xs={6} className="text-end">
              <IconButton onClick={() => setOpenUnusedLicenseUpdate(false)} title="Close">
                <HighlightOff />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Box className="space-y-6">
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="broker">Broker</InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.background.main,
                        },
                      },
                    }}
                    labelId="broker"
                    name="broker"
                    id="broker"
                    label="Broker"
                    value={formik.values.broker}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.broker && Boolean(formik.errors.broker)}
                  >
                    <MenuItem value="" disabled key={"first"}>
                      Select broker (optional)
                    </MenuItem>
                    {brokerList?.map((broker) => (
                      <MenuItem value={broker?.broker_id} key={broker?._id}>
                        {broker?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.broker && formik.errors.broker && (
                    <Typography className="!text-[#f44336] !text-[12px] !mt-1">{formik.touched.broker && formik.errors.broker}</Typography>
                  )}
                </FormControl>
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                      textAlign: "center",
                    },
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Expire Date"
                      disablePast
                      slotProps={{ textField: { size: "small" } }}
                      name="expire_date"
                      value={formik.values.expire_date}
                      onChange={(value) => formik.setFieldValue("expire_date", value)}
                      onBlur={formik.handleBlur}
                      error={formik.touched.expire_date && Boolean(formik.errors.expire_date)}
                    />
                  </LocalizationProvider>
                  {formik.touched.expire_date && formik.errors.expire_date && (
                    <Typography className="!text-[#f44336] !text-[12px] !mt-1">{formik.touched.expire_date && formik.errors.expire_date}</Typography>
                  )}
                </FormControl>
                <Box className="flex justify-end">
                  <Button
                    sx={{
                      background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                      boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                      color: "white",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      width: "100px",
                      pointerEvents: loading ? "none" : "auto",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : "Update"}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
});

export default UpdateUnusedLicenseModal;
