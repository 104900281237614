import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    backendResultList: [],
}

export const backendResultSlice = createSlice({
    name: 'backendResult',
    initialState,
    reducers: {
        setBackendResultList: (state, action) => {
            state.backendResultList = action.payload
        },
        addBackendResultList: (state, action) => {
            state.backendResultList.push(action.payload)
        },
        updateBackendResultList: (state, action) => {
            const index = state.backendResultList.findIndex(update => update._id === action.payload._id)
            if (index !== -1) {
                state.backendResultList[index] = {
                    ...state.backendResultList[index],
                    ...action.payload,
                }
            }
        },
        removeBackendResultList: (state, action) => {
            state.backendResultList = state.backendResultList.filter(update => update._id !== action.payload)
        },
    },
})

export const { setBackendResultList, removeBackendResultList, addBackendResultList, updateBackendResultList } = backendResultSlice.actions

export default backendResultSlice.reducer