import api from "api";
import { getAuthToken } from "server/auth/auth";
export async function getPlanList() {
    try {
        return await api.get("/plan", {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ getPlanList ~ error:", error)
    }
}

export async function createPlan(data) {
    try {
        return await api.post("/plan", data, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ createPlan ~ error:", error)
    }
}


export async function updatePlan(data) {
    try {
        return await api.put("/plan", data, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ updatePlan ~ error:", error)
    }
}

export async function deletePlan(id) {
    try {
        return await api.delete(`/plan/${id}`, {
            headers: {
                Authorization: getAuthToken(),
            },
        });
    } catch (error) {
        console.log("🚀 ~ deletePlan ~ error:", error)
    }
}
