import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    licenseList: [],
    extendedLicenseList: [],
    unUsedLicense: [],
}

export const licenseSlice = createSlice({
    name: 'license',
    initialState,
    reducers: {
        setLicenseList: (state, action) => {
            state.licenseList = action.payload
        },
        addLicenseList: (state, action) => {
            state.licenseList.push(action.payload)
        },
        deleteLicenseList: (state, action) => {
            state.licenseList = state.licenseList.filter(license => license._id !== action.payload)
        },
        updateLicenseList: (state, action) => {
            const index = state.licenseList.findIndex(license => license._id === action.payload._id)
            if (index !== -1) {
                state.licenseList[index] = {
                    ...state.licenseList[index],
                    ...action.payload,
                }
            }
        },
        setExtendedLicenseList: (state, action) => {
            state.extendedLicenseList = action.payload
        },
        setUnusedLicenseList: (state, action) => {
            state.unUsedLicense = action.payload
        },
        removeLicenseList: (state, action) => {
            state.unUsedLicense = state.unUsedLicense.filter(license => license._id !== action.payload)
        },
        deleteUnusedLicenseList: (state, action) => {
            state.unUsedLicense = state.unUsedLicense.filter(license => license._id !== action.payload)
        },
    },
})

export const { setLicenseList, setExtendedLicenseList, addLicenseList, updateLicenseList, deleteLicenseList, setUnusedLicenseList, removeLicenseList, deleteUnusedLicenseList } = licenseSlice.actions

export default licenseSlice.reducer