import { Grid, Skeleton, Typography } from "@mui/material";
import { memo } from "react";

const LatestVersionLoader = memo(() => {
  return (
    <Grid container sx={{ justifyContent: "center" }}>
      <Grid item xs={12}>
        <Typography>
          <Skeleton height={65} />
        </Typography>
        <Typography>
          <Skeleton height={65} />
        </Typography>
        <Typography>
          <Skeleton height={65} />
        </Typography>
        <Typography>
          <Skeleton height={65} />
        </Typography>
        <Typography>
          <Skeleton height={65} />
        </Typography>
      </Grid>
    </Grid>
  );
});

export default LatestVersionLoader;
