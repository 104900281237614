import { CloudOutlined, MoreHoriz } from "@mui/icons-material";
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { Fragment, memo, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import DeleteHelpCenterModal from "./DeleteHelpCenterModal";
import HelpActionPopOver from "./HelpActionPopOver";
import UpdateHelpModal from "./UpdateHelpModal";

const HelpCenterTable = memo(({ searchText }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const loading = useSelector((state) => state.loader.helpMessageLoader);
  const theme = useTheme();
  const helpMessageList = useSelector((state) => state.help.helpMessageList);
  const [currentHelpRow, setCurrentHelpRow] = useState({});
  const [updateHelpModalOpen, setUpdateHelpModalOpen] = useState(false);
  const [deleteHelpModalOpen, setDeleteHelpModalOpen] = useState(false);
  const [helpActionPopover, setHelpActionPopover] = useState(null);
  const helpActionPopoverOpen = Boolean(helpActionPopover);

  const handleClickHelpActionPopover = useCallback((event) => {
    setHelpActionPopover(event.currentTarget);
  }, []);

  const handleCloseHelpActionPopover = useCallback(() => {
    setHelpActionPopover(null);
  }, []);

  const filteredHelpMessageList = useMemo(() => {
    if (!searchText) return helpMessageList;
    const search = searchText.toLowerCase();
    return helpMessageList.filter((help) => {
      const email = help.login.toLowerCase();
      const login = help.login.toString().toLowerCase();
      const subject = help.subject.toLowerCase();
      return subject.includes(search) || email.includes(search) || login.includes(search);
    });
  }, [helpMessageList, searchText]);

  // Calculate the rows to display based on the current page and rows per page
  const displayedRows = filteredHelpMessageList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = useCallback((_, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  return (
    <Fragment>
      <Box>
        <TableContainer sx={{ height: "calc(100vh - 190px)", width: "100%" }} className="etrsc-scrollbar">
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ background: theme.palette.background.tableHeader }} className="shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]">
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Login
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Subject
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                    width: "30%",
                  }}
                >
                  Message
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Message Received
                </TableCell>
                <TableCell
                  sx={{
                    borderBottom: "none",
                    color: theme.palette.text.white,
                    fontWeight: 500,
                    fontSize: "16px",
                    background: theme.palette.background.tableHeader,
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center" colSpan={7} sx={{ color: theme.palette.text.white }} className="!font-bold !text-base">
                    <CircularProgress size={28} color="success" />
                  </TableCell>
                </TableRow>
              ) : displayedRows?.length > 0 ? (
                displayedRows?.map((item, i) => (
                  <Fragment key={i}>
                    <TableRow onClick={() => setCurrentHelpRow(item)} className={`shadow-[0px 17px 42.2px 0px rgba(0, 0, 0, 0.25)] backdrop-blur-[blur(15px)]`}>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {item?.email}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {item?.login}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {item?.subject}
                      </TableCell>

                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {item?.message}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {moment(item?.createdAt).format("HH:mm:ss YYYY-MM-DD")}
                      </TableCell>

                      <TableCell
                        onClick={(event) => handleClickHelpActionPopover(event)}
                        sx={{
                          borderBottom: `1px solid ${theme.palette.primary.border}`,
                          color: theme.palette.text.white,
                          fontWeight: 500,
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                      >
                        <MoreHoriz />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))
              ) : (
                <TableRow className="!h-5 flex justify-center items-center">
                  <TableCell
                    align="center"
                    colSpan={7}
                    sx={{
                      borderBottom: "none",
                      color: theme.palette.text.white,
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    <CloudOutlined sx={{ fontSize: "40px" }} />
                    <Typography>No data found!</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
          <TablePagination
            component="div"
            count={helpMessageList?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
          />
        </Box>
      </Box>
      <HelpActionPopOver {...{ helpActionPopover, helpActionPopoverOpen, handleCloseHelpActionPopover, setUpdateHelpModalOpen, setDeleteHelpModalOpen }} />
      <UpdateHelpModal {...{ updateHelpModalOpen, setUpdateHelpModalOpen, currentHelpRow }} />
      <DeleteHelpCenterModal {...{ deleteHelpModalOpen, setDeleteHelpModalOpen, currentHelpRow }} />
    </Fragment>
  );
});

export default HelpCenterTable;
