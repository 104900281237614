import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    helpMessageList: [],
}

export const helpSlice = createSlice({
    name: 'help',
    initialState,
    reducers: {
        setHelpMessageList: (state, action) => {
            state.helpMessageList = action.payload
        },
        updateHelpMessageList: (state, action) => {
            const index = state.helpMessageList.findIndex(help => help._id === action.payload._id)
            if (index !== -1) {
                state.helpMessageList[index] = {
                    ...state.helpMessageList[index],
                    ...action.payload,
                }
            }
        },
        removeHelpMessageList: (state, action) => {
            state.helpMessageList = state.helpMessageList.filter(help => help._id !== action.payload)
        },
    },
})

export const { setHelpMessageList, removeHelpMessageList, updateHelpMessageList } = helpSlice.actions

export default helpSlice.reducer