import { Delete, Edit } from "@mui/icons-material";
import { ListItemIcon, Menu, MenuItem, useTheme } from "@mui/material";
import { Fragment, memo } from "react";

const UnusedTableActionPopOver = memo(
  ({ anchorElUnusedActionPopover, unUsedActionPopoverOpen, handleCloseUnusedActionPopover, setOpenUnusedLicenseUpdate, setOpenUnusedLicenseDelete }) => {
    const theme = useTheme();

    return (
      <Fragment>
        <Menu
          anchorEl={anchorElUnusedActionPopover}
          open={unUsedActionPopoverOpen}
          onClose={handleCloseUnusedActionPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: theme.palette.background.main,
            },
          }}
        >
          <MenuItem
            sx={{ fontSize: "14px" }}
            onClick={() => {
              setOpenUnusedLicenseUpdate(true);
              handleCloseUnusedActionPopover();
            }}
          >
            <ListItemIcon>
              <Edit sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "14px" }}
            onClick={() => {
              setOpenUnusedLicenseDelete(true);
              handleCloseUnusedActionPopover();
            }}
          >
            <ListItemIcon>
              <Delete sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            Delete
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }
);

export default UnusedTableActionPopOver;
