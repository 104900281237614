import { Box, Button, Typography, useTheme } from "@mui/material";
import SearchBar from "components/common/SearchBar";
import Layout from "components/Dashboard/Layout";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBackendUpdateList } from "redux/features/backendUpdate/backendUpdateSlice";
import { setBackendUpdateLoader } from "redux/features/loader/loaderSlice";
import { getBackendUpdateList } from "server/backendUpdate/backendUpdate";
import BackendUpdateTable from "./BackendUpdateTable";
import CreateBackendUpdateModal from "./CreateBackendUpdateModal";
import { getBrokerList } from "server/broker/broker";
import { setBrokerList } from "redux/features/broker/brokerSlice";

const BackendForceUpdate = memo(() => {
  const theme = useTheme();
  const [createBackendUpdateModalOpen, setCreateBackendUpdateModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const placeholderText = "Search by version or code";

  const handleSearchTextChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  const fetchBackendUpdateList = async () => {
    try {
      dispatch(setBackendUpdateLoader(true));
      const result = await getBackendUpdateList();
      if (result?.data?.success) {
        dispatch(setBackendUpdateList(result?.data?.data));
        dispatch(setBackendUpdateLoader(false));
      } else {
        dispatch(setBackendUpdateLoader(false));
      }
    } catch (error) {
      dispatch(setBackendUpdateLoader(false));
    }
  };
  const fetchBrokerList = async () => {
    const result = await getBrokerList();
    if (result?.data?.success) {
      dispatch(setBrokerList(result?.data?.data));
    }
  };

  useEffect(() => {
    fetchBackendUpdateList();
    fetchBrokerList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Layout>
      <Box>
        <Box className="flex items-center justify-between my-4">
          <Typography variant="h6" style={{ marginLeft: "15px", color: theme.palette.text.white }}>
            Backend Update List
          </Typography>
          <Box sx={{ marginRight: "15px", display: "flex", alignItems: "center", gap: 2 }}>
            <Box>
              <SearchBar {...{ searchText, handleSearchTextChange, placeholderText }} />
            </Box>
            <Button
              onClick={() => setCreateBackendUpdateModalOpen(true)}
              variant="contained"
              sx={{
                background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                color: "white",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
            >
              Backend Update Create
            </Button>
          </Box>
        </Box>
        <BackendUpdateTable {...{ searchText }} />
      </Box>
      <CreateBackendUpdateModal {...{ createBackendUpdateModalOpen, setCreateBackendUpdateModalOpen }} />
    </Layout>
  );
});

export default BackendForceUpdate;
