import { useTheme } from "@emotion/react";
import { HighlightOff } from "@mui/icons-material";
import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import countries from "data/countryList";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBrokerList } from "redux/features/broker/brokerSlice";
import { updateWhiteLabelBroker } from "server/broker/broker";
import * as yup from "yup";

const firstStepFormValidationSchema = yup.object({
  name: yup.string("Enter your name").required("Name is required"),
  email: yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
  // phone: yup.string("Enter your phone").required("Phone is required"),
  country: yup.string("Enter your country").required("Country is required"),
  state: yup.string("Enter your state").required("State is required"),
  zip: yup.string("Enter your zip").required("Zip is required"),
  server: yup.string("Enter your server").required("Server is required"),
  address: yup.string("Enter your address").required("Address is required"),
  plan: yup.string("Select your plan").required("Plan is required"),
  broker_id: yup.string("Enter broker id").required("Broker id is required"),
});

const secondStepFormValidationSchema = yup.object({
  company_name: yup.string("Enter your company name").required("Company name is required"),
  company_email: yup.string("Enter your company email").email("Enter a valid email").required("Company email is required"),
  company_url: yup.string("Enter your company url").required("Company url is required"),
  // company_phone: yup.string("Enter your company phone").required("Company phone is required"),
  company_address: yup.string("Enter your company address").required("Company address is required"),
  logo_dark_url: yup.string("Enter your logo dark url").required("Logo dark url is required"),
  logo_url: yup.string("Enter your logo url").required("Logo url is required"),
  status: yup.string("Enter your status").required("Status is required"),
  type: yup.string("Enter your type").required("Type is required"),
});

const UpdateWhiteLabelBrokerModal = memo(({ openWhiteLabelBrokerUpdateModal, setWhiteLabelBrokerUpdateModal, selectedBroker }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const planList = useSelector((state) => state.plan.planList);
  const [activeStep, setActiveStep] = useState(0);
  const [firstStepValues, setFirstStepValues] = useState({});

  const handleNextStep = useCallback((step) => {
    setActiveStep(step);
  }, []);

  return (
    <Modal
      open={openWhiteLabelBrokerUpdateModal}
      onClose={() => setWhiteLabelBrokerUpdateModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 480,
          backgroundColor: theme.palette.background.main,
          borderRadius: "5px",
          padding: "18px 25px",
          outline: "none",
        }}
      >
        {/* Top Section */}
        <Grid container rowSpacing={1} columnSpacing={2} alignItems={"center"}>
          <Grid item xs={6}>
            <Typography sx={{ color: theme.palette.text.white }} className="!capitalize">
              Update White Label Broker
            </Typography>
          </Grid>
          <Grid item xs={6} className="text-end">
            <IconButton onClick={() => setWhiteLabelBrokerUpdateModal(false)} title="Close">
              <HighlightOff />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ color: theme.palette.text.white, fontSize: "18px" }} className="!capitalize !mb-4">
              {activeStep === 0 ? "Broker Information" : activeStep === 1 ? "Broker Company Information" : null}
            </Typography>
          </Grid>
        </Grid>
        {activeStep === 0 && (
          <Formik
            initialValues={{
              name: selectedBroker?.name || "",
              email: selectedBroker?.email || "",
              phone: selectedBroker?.phone || "",
              country: selectedBroker?.country || "",
              state: selectedBroker?.state || "",
              zip: selectedBroker?.zip || "",
              server: selectedBroker?.server || "",
              address: selectedBroker.address || "",
              plan: selectedBroker?.plan_id || "",
              broker_id: selectedBroker?.broker_id || "",
            }}
            validationSchema={firstStepFormValidationSchema}
            enableReinitialize={true}
            onSubmit={async (values) => {
              const data = {
                name: values.name,
                email: values.email,
                phone: values.phone,
                country: values.country,
                state: values.state,
                zip: values.zip,
                server: values.server,
                address: values.address,
                plan_id: values.plan,
                broker_id: values.broker_id,
              };
              setFirstStepValues(data);
              handleNextStep(1);
            }}
          >
            {({ handleChange, handleBlur, values, errors, touched, handleSubmit }) => (
              <Box sx={{ display: "flex", flexDirection: "column" }} className="space-y-4">
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="Name"
                        size="small"
                        type={"text"}
                        placeholder="Your name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="Email"
                        size="small"
                        type={"email"}
                        placeholder="Your email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="Phone"
                        size="small"
                        type={"text"}
                        placeholder="Your phone"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        error={touched.phone && Boolean(errors.phone)}
                        helperText={touched.phone && errors.phone}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="Server"
                        size="small"
                        type={"text"}
                        placeholder="http://190.92.173.200:5000"
                        name="server"
                        value={values.server}
                        onChange={handleChange}
                        error={touched.server && Boolean(errors.server)}
                        helperText={touched.server && errors.server}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <InputLabel id="country">Country</InputLabel>
                      <Select
                        MenuProps={{
                          PaperProps: {
                            className: "etrsc-scrollbar",
                            style: {
                              background: theme.palette.background.main,
                              height: "200px",
                              width: "180px",
                            },
                          },
                        }}
                        labelId="country"
                        id="country"
                        label="Country"
                        name="country"
                        value={values.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.country && Boolean(errors.country)}
                      >
                        <MenuItem value="" disabled key={"first"}>
                          Select country
                        </MenuItem>
                        {countries?.map((country) => (
                          <MenuItem disableRipple key={country?.code} value={country?.label}>
                            {country?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.country && errors.country && <Typography className="!text-[#f44336] !text-[12px] !mt-1">{touched.country && errors.country}</Typography>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="State"
                        size="small"
                        type={"text"}
                        placeholder="Your state"
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                        error={touched.state && Boolean(errors.state)}
                        helperText={touched.state && errors.state}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="Zip"
                        size="small"
                        type={"text"}
                        placeholder="Your zip"
                        name="zip"
                        value={values.zip}
                        onChange={handleChange}
                        error={touched.zip && Boolean(errors.zip)}
                        helperText={touched.zip && errors.zip}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="Address"
                        size="small"
                        type={"text"}
                        placeholder="Your address"
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        error={touched.address && Boolean(errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <InputLabel id="plan">Plan</InputLabel>
                      <Select
                        MenuProps={{
                          PaperProps: {
                            style: {
                              background: theme.palette.background.main,
                            },
                          },
                        }}
                        labelId="plan"
                        id="plan"
                        label="Plan"
                        name="plan"
                        value={values.plan}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.plan && Boolean(errors.plan)}
                      >
                        <MenuItem value="" disabled key={"first"}>
                          Select Plan
                        </MenuItem>
                        {planList.map((plan) => (
                          <MenuItem key={plan?._id} value={plan?._id}>
                            {plan?.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.plan && errors.plan && <Typography className="!text-[#f44336] !text-[12px] !mt-1">{touched.plan && errors.plan}</Typography>}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="Local Broker ID"
                        size="small"
                        type={"text"}
                        placeholder="Your local broker id"
                        id="broker_id"
                        name="broker_id"
                        value={values.broker_id}
                        onChange={handleChange}
                        error={touched.broker_id && Boolean(errors.broker_id)}
                        helperText={touched.broker_id && errors.broker_id}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Box className="flex justify-between items-center">
                      <Button
                        disabled={activeStep === 0}
                        sx={{
                          background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                          boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                          color: "white",
                          fontWeight: 500,
                          textTransform: "capitalize",
                          width: "100px",
                        }}
                        type="submit"
                        variant="contained"
                      >
                        Back
                      </Button>
                      <Button
                        sx={{
                          background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                          boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                          color: "white",
                          fontWeight: 500,
                          textTransform: "capitalize",
                          width: "100px",
                        }}
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit}
                      >
                        Next
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Formik>
        )}
        {activeStep === 1 && (
          <Formik
            initialValues={{
              company_name: selectedBroker?.company_name || "",
              company_email: selectedBroker?.company_email || "",
              company_url: selectedBroker?.company_url || "",
              company_phone: selectedBroker?.company_phone || "",
              company_address: selectedBroker?.company_address || "",
              logo_dark_url: selectedBroker?.logo_dark_url || "",
              logo_url: selectedBroker?.logo_url || "",
              status: selectedBroker?.status || "",
              type: selectedBroker?.type || "",
            }}
            validationSchema={secondStepFormValidationSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
              setLoading(true);
              const secondStepValues = {
                id: selectedBroker?._id,
                broker_id: selectedBroker?.broker_id,
                company_name: values.company_name,
                company_email: values.company_email,
                company_url: values.company_url,
                company_phone: values.company_phone,
                company_address: values.company_address,
                logo_dark_url: values.logo_dark_url,
                logo_url: values.logo_url,
                status: values.status,
                type: values.type,
              };
              const data = { ...firstStepValues, ...secondStepValues };
              try {
                const result = await updateWhiteLabelBroker(data);
                if (result?.data?.success) {
                  dispatch(updateBrokerList(result?.data?.data));
                  enqueueSnackbar(result?.data?.message, { variant: "success" });
                  setWhiteLabelBrokerUpdateModal(false);
                  resetForm();
                  handleNextStep(0);
                } else {
                  enqueueSnackbar(result?.data?.message, { variant: "error" });
                }
              } catch (error) {
                enqueueSnackbar("An error occurred while updating the broker.", { variant: "error" });
              } finally {
                setLoading(false);
              }
            }}
          >
            {({ handleChange, handleBlur, values, errors, handleSubmit, touched }) => (
              <Box sx={{ display: "flex", flexDirection: "column" }} className="space-y-3">
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="Company name"
                        size="small"
                        type={"text"}
                        placeholder="Your company name"
                        name="company_name"
                        value={values.company_name}
                        onChange={handleChange}
                        error={touched.company_name && Boolean(errors.company_name)}
                        helperText={touched.company_name && errors.company_name}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="Company email"
                        size="small"
                        type={"text"}
                        placeholder="Your company email"
                        name="company_email"
                        value={values.company_email}
                        onChange={handleChange}
                        error={touched.company_email && Boolean(errors.company_email)}
                        helperText={touched.company_email && errors.company_email}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="Company URL"
                        size="small"
                        type={"text"}
                        placeholder="Your company url"
                        name="company_url"
                        value={values.company_url}
                        onChange={handleChange}
                        error={touched.company_url && Boolean(errors.company_url)}
                        helperText={touched.company_url && errors.company_url}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="Company phone"
                        size="small"
                        type={"text"}
                        placeholder="Your company phone"
                        name="company_phone"
                        value={values.company_phone}
                        onChange={handleChange}
                        error={touched.company_phone && Boolean(errors.company_phone)}
                        helperText={touched.company_phone && errors.company_phone}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="Company address"
                        size="small"
                        type={"text"}
                        placeholder="Your company address"
                        name="company_address"
                        value={values.company_address}
                        onChange={handleChange}
                        error={touched.company_address && Boolean(errors.company_address)}
                        helperText={touched.company_address && errors.company_address}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="Logo dark url"
                        size="small"
                        type={"text"}
                        placeholder="Logo dark url"
                        name="logo_dark_url"
                        value={values.logo_dark_url}
                        onChange={handleChange}
                        error={touched.logo_dark_url && Boolean(errors.logo_dark_url)}
                        helperText={touched.logo_dark_url && errors.logo_dark_url}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <TextField
                        label="Logo url"
                        size="small"
                        type={"text"}
                        placeholder="Logo url"
                        name="logo_url"
                        value={values.logo_url}
                        onChange={handleChange}
                        error={touched.logo_url && Boolean(errors.logo_url)}
                        helperText={touched.logo_url && errors.logo_url}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <InputLabel id="status">Status</InputLabel>
                      <Select
                        MenuProps={{
                          PaperProps: {
                            style: {
                              background: theme.palette.background.main,
                            },
                          },
                        }}
                        labelId="status"
                        id="status"
                        label="Status"
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.status && Boolean(errors.status)}
                      >
                        <MenuItem value="" disabled key={"first"}>
                          Select Status
                        </MenuItem>
                        <MenuItem value={"active"}>Active</MenuItem>
                        <MenuItem value={"inactive"}>Inactive</MenuItem>
                      </Select>
                      {touched.status && errors.status && <Typography className="!text-[#f44336] !text-[12px] !mt-1">{touched.status && errors.status}</Typography>}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1px solid var(--primary-color)",
                          },
                          "&:hover fieldset": {
                            border: "1px solid var(--secondary-color)",
                          },
                          "&.Mui-focused fieldset": {
                            border: `1px solid var(--secondary-color)`,
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: theme.palette.text.white,
                          "&.Mui-focused": {
                            color: theme.palette.text.white,
                          },
                        },
                      }}
                      size="small"
                    >
                      <InputLabel id="type">Type</InputLabel>
                      <Select
                        MenuProps={{
                          PaperProps: {
                            style: {
                              background: theme.palette.background.main,
                            },
                          },
                        }}
                        labelId="type"
                        id="type"
                        label="Type"
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.type && Boolean(errors.type)}
                      >
                        <MenuItem value="" disabled key={"first"}>
                          Select type
                        </MenuItem>
                        <MenuItem value={"live"}>Live</MenuItem>
                        <MenuItem value={"demo"}>Demo</MenuItem>
                      </Select>
                      {touched.type && errors.type && <Typography className="!text-[#f44336] !text-[12px] !mt-1">{touched.type && errors.type}</Typography>}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <Box className="flex justify-between items-center">
                      <Button
                        sx={{
                          background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                          boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                          color: "white",
                          fontWeight: 500,
                          textTransform: "capitalize",
                          width: "100px",
                        }}
                        type="submit"
                        variant="contained"
                        onClick={() => handleNextStep(0)}
                      >
                        Back
                      </Button>
                      <Button
                        sx={{
                          background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                          boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                          color: "white",
                          fontWeight: 500,
                          textTransform: "capitalize",
                          width: "100px",
                          pointerEvents: loading ? "none" : "auto",
                        }}
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit}
                      >
                        {loading ? <CircularProgress size={24} color="inherit" /> : "Update"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Formik>
        )}
      </Box>
    </Modal>
  );
});

export default UpdateWhiteLabelBrokerModal;
