import { Delete, Edit } from "@mui/icons-material";
import { ListItemIcon, Menu, MenuItem, useTheme } from "@mui/material";
import { Fragment, memo, useCallback } from "react";

const HelpActionPopOver = memo(({ helpActionPopover, helpActionPopoverOpen, handleCloseHelpActionPopover, setUpdateHelpModalOpen, setDeleteHelpModalOpen }) => {
  const theme = useTheme();

  const handleUpdateHelp = useCallback(() => {
    setUpdateHelpModalOpen(true);
    handleCloseHelpActionPopover();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteHelp = useCallback(() => {
    setDeleteHelpModalOpen(true);
    handleCloseHelpActionPopover();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Menu
        anchorEl={helpActionPopover}
        open={helpActionPopoverOpen}
        onClose={handleCloseHelpActionPopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            overflow: "hidden",
            backgroundColor: theme.palette.background.main,
          },
        }}
      >
        <MenuItem sx={{ fontSize: "14px" }} onClick={handleUpdateHelp}>
          <ListItemIcon>
            <Edit sx={{ fontSize: "20px" }} />
          </ListItemIcon>
          Edit
        </MenuItem>

        <MenuItem sx={{ fontSize: "14px" }} onClick={handleDeleteHelp}>
          <ListItemIcon>
            <Delete sx={{ fontSize: "20px" }} />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </Fragment>
  );
});

export default HelpActionPopOver;
