import { Box, Button, Typography, useTheme } from "@mui/material";
import SearchBar from "components/common/SearchBar";
import Layout from "components/Dashboard/Layout";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBrokerList } from "redux/features/broker/brokerSlice";
import { setLicenseList } from "redux/features/license/licenseSlice";
import { setBrokerListLoader, setLicenseListLoader, setPlanLoader } from "redux/features/loader/loaderSlice";
import { setPlanList } from "redux/features/plan/planSlice";
import { getBrokerList } from "server/broker/broker";
import { getLicenseList } from "server/license/license";
import { getPlanList } from "server/plan/plan";
import BrokerTable from "./BrokerTable";
import CreateBrokerModal from "./CreateBrokerModal";
import CreateWhiteLabelBrokerModal from "./CreateWhiteLabelBrokerModal";

const Broker = memo(() => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openBrokerModal, setOpenBrokerModal] = useState(false);
  const [openWhiteLabelBrokerModal, setOpenWhiteLabelBrokerModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const placeholderText = "Search by name, email";

  const fetchLicenseList = async () => {
    dispatch(setLicenseListLoader(true));
    const result = await getLicenseList();
    if (result?.data?.status) {
      dispatch(setLicenseList(result?.data?.data));
      dispatch(setLicenseListLoader(false));
    } else {
      dispatch(setLicenseListLoader(false));
    }
  };

  const fetchBrokerList = async () => {
    try {
      dispatch(setBrokerListLoader(true));
      const result = await getBrokerList();
      if (result?.data?.success) {
        dispatch(setBrokerList(result?.data?.data));
        dispatch(setBrokerListLoader(false));
      } else {
        dispatch(setBrokerListLoader(false));
      }
    } catch (error) {
      dispatch(setBrokerListLoader(false));
    }
  };

  const fetchPlanList = async () => {
    try {
      dispatch(setPlanLoader(true));
      const result = await getPlanList();
      if (result?.data?.success) {
        dispatch(setPlanList(result?.data?.data));
        dispatch(setPlanLoader(false));
      } else {
        dispatch(setPlanLoader(false));
      }
    } catch (error) {
      dispatch(setPlanLoader(false));
    }
  };

  const handleSearchTextChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  useEffect(() => {
    fetchBrokerList();
    fetchLicenseList();
    fetchPlanList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <Box>
        <Box className="flex items-center justify-between my-4">
          <Typography variant="h6" style={{ marginLeft: "15px", color: theme.palette.text.white }}>
            Broker List
          </Typography>
          <Box sx={{ marginRight: "15px", display: "flex", alignItems: "center", gap: 2 }}>
            <Box>
              <SearchBar {...{ searchText, handleSearchTextChange, placeholderText }} />
            </Box>
            <Button
              onClick={() => setOpenWhiteLabelBrokerModal(true)}
              variant="contained"
              sx={{
                background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                color: "white",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
            >
              Create white label broker
            </Button>
            <Button
              onClick={() => setOpenBrokerModal(true)}
              variant="contained"
              sx={{
                background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                color: "white",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
            >
              Add Broker
            </Button>
          </Box>
        </Box>
        <BrokerTable {...{ searchText }} />
      </Box>
      <CreateBrokerModal {...{ openBrokerModal, setOpenBrokerModal }} />
      <CreateWhiteLabelBrokerModal {...{ openWhiteLabelBrokerModal, setOpenWhiteLabelBrokerModal }} />
    </Layout>
  );
});

export default Broker;
