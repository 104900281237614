import { Delete, Edit } from "@mui/icons-material";
import { ListItemIcon, Menu, MenuItem, useTheme } from "@mui/material";
import { Fragment, memo, useCallback } from "react";

const PlanActionPopover = memo(({ planActionPopover, planActionPopoverOpen, handleClosePlanActionPopover, setUpdatePlanModalOpen, setDeletePlanModalOpen }) => {
  const theme = useTheme();

  const handleUpdatePlan = useCallback(() => {
    setUpdatePlanModalOpen(true);
    handleClosePlanActionPopover();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeletePlan = useCallback(() => {
    setDeletePlanModalOpen(true);
    handleClosePlanActionPopover();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Menu
        anchorEl={planActionPopover}
        open={planActionPopoverOpen}
        onClose={handleClosePlanActionPopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            overflow: "hidden",
            backgroundColor: theme.palette.background.main,
          },
        }}
      >
        <MenuItem sx={{ fontSize: "14px" }} onClick={handleUpdatePlan}>
          <ListItemIcon>
            <Edit sx={{ fontSize: "20px" }} />
          </ListItemIcon>
          Edit
        </MenuItem>

        <MenuItem sx={{ fontSize: "14px" }} onClick={handleDeletePlan}>
          <ListItemIcon>
            <Delete sx={{ fontSize: "20px" }} />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </Fragment>
  );
});

export default PlanActionPopover;
