import { Box, Typography, useTheme } from "@mui/material";
import SearchBar from "components/common/SearchBar";
import Layout from "components/Dashboard/Layout";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setExtendedLicenseList } from "redux/features/license/licenseSlice";
import { setExtendedLicenseListLoader } from "redux/features/loader/loaderSlice";
import { getExtendedLicenseList } from "server/license/license";
import ExtendedLicenseTable from "./ExtendedLicenseTable";

const ExtendedLicense = memo(() => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const placeholderText = "Search by broker id";

  const fetchExtendedLicenseList = async () => {
    dispatch(setExtendedLicenseListLoader(true));
    const result = await getExtendedLicenseList();
    if (result?.data?.status) {
      dispatch(setExtendedLicenseList(result?.data?.data));
      dispatch(setExtendedLicenseListLoader(false));
    } else {
      dispatch(setExtendedLicenseListLoader(false));
    }
  };

  const handleSearchTextChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  useEffect(() => {
    fetchExtendedLicenseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Box>
        <Box className="flex items-center justify-between my-4">
          <Typography variant="h6" style={{ marginLeft: "15px", color: theme.palette.text.white }}>
            Extended License
          </Typography>
          <Box className="mr-3">
            <SearchBar {...{ searchText, handleSearchTextChange, placeholderText }} />
          </Box>
        </Box>
        <ExtendedLicenseTable {...{ searchText }} />
      </Box>
    </Layout>
  );
});

export default ExtendedLicense;
