import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    uploadFileList: [],
}

export const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        setUploadFileList: (state, action) => {
            state.uploadFileList = action.payload
        },

        removeUploadFile: (state, action) => {
            state.uploadFileList = state.uploadFileList.filter(file => file._id !== action.payload)
        },
    },
})

export const { setUploadFileList, removeUploadFile } = uploadSlice.actions

export default uploadSlice.reducer