import { useTheme } from "@emotion/react";
import { Dashboard, ExpandLess, ExpandMore, Inventory, Logout, PeopleAlt, Storage, SupportAgent, Update, UploadFile, VerifiedUser } from "@mui/icons-material";
import { Box, Collapse } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logOut } from "redux/features/auth/authSlice";

const Sidebar = () => {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState("/");
  const [openLicenseDropdown, setOpenLicenseDropdown] = useState(false);
  const [openUploadDropdown, setOpenUploadDropdown] = useState(false);
  const [openBackendDropdown, setOpenBackendDropdown] = useState(false);
  const dispatch = useDispatch();

  const handleActiveMenu = useCallback(
    (route) => {
      navigate(route);
      setActiveMenu(route);
    },
    [navigate]
  );

  const handleLicenseDropdown = useCallback(() => {
    setOpenLicenseDropdown((prev) => !prev);
  }, []);

  const handleUploadDropdown = useCallback(() => {
    setOpenUploadDropdown((prev) => !prev);
  }, []);

  const handleBackendDropdown = useCallback(() => {
    setOpenBackendDropdown((prev) => !prev);
  }, []);

  useEffect(() => {
    setActiveMenu(location.pathname);

    if (["/license", "/extended-license", "/unused-license"].includes(location.pathname)) {
      setOpenLicenseDropdown(true);
    }
    if (["/file-upload", "/upload-file-list"].includes(location.pathname)) {
      setOpenUploadDropdown(true);
    }
    if (["/backend-force-update", "/backend-result"].includes(location.pathname)) {
      setOpenBackendDropdown(true);
    }
  }, [location.pathname]);

  const handleLogout = useCallback(() => {
    dispatch(logOut());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List sx={{ width: "100%", maxWidth: 360, height: "65vh", bgcolor: theme.palette.primary.main, display: "flex", flexDirection: "column" }} component="nav">
      <Box className="text-white">
        <ListItemButton disableRipple onClick={() => handleActiveMenu("/")}>
          <ListItemIcon>
            <Dashboard sx={{ color: activeMenu === "/" ? "var(--active-color)" : "var(--text-color)" }} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" sx={{ color: activeMenu === "/" ? "var(--active-color)" : "var(--text-color)" }} />
        </ListItemButton>
        <ListItemButton disableRipple onClick={() => handleActiveMenu("/broker")}>
          <ListItemIcon>
            <PeopleAlt sx={{ color: activeMenu === "/broker" ? "var(--active-color)" : "var(--text-color)" }} />
          </ListItemIcon>
          <ListItemText primary="Broker List" sx={{ color: activeMenu === "/broker" ? "var(--active-color)" : "var(--text-color)" }} />
        </ListItemButton>

        <ListItemButton
          disableRipple
          onClick={() => {
            handleLicenseDropdown();
          }}
        >
          <ListItemIcon>
            <VerifiedUser sx={{ color: "var(--text-color)" }} />
          </ListItemIcon>
          <ListItemText primary="License" sx={{ color: "var(--text-color)" }} />
          {openLicenseDropdown ? <ExpandLess sx={{ color: "var(--text-color)" }} /> : <ExpandMore sx={{ color: "var(--text-color)" }} />}
        </ListItemButton>

        <Collapse in={openLicenseDropdown} timeout="auto" unmountOnExit>
          <List component="nav">
            <ListItemButton disableRipple onClick={() => handleActiveMenu("/license")}>
              <ListItemIcon>
                <VerifiedUser sx={{ color: activeMenu === "/license" ? "var(--active-color)" : "var(--text-color)" }} />
              </ListItemIcon>
              <ListItemText primary="License List" sx={{ color: activeMenu === "/license" ? "var(--active-color)" : "var(--text-color)" }} />
            </ListItemButton>

            <ListItemButton disableRipple onClick={() => handleActiveMenu("/extended-license")}>
              <ListItemIcon>
                <VerifiedUser sx={{ color: activeMenu === "/extended-license" ? "var(--active-color)" : "var(--text-color)" }} />
              </ListItemIcon>
              <ListItemText primary="Extended License" sx={{ color: activeMenu === "/extended-license" ? "var(--active-color)" : "var(--text-color)" }} />
            </ListItemButton>
            <ListItemButton disableRipple onClick={() => handleActiveMenu("/unused-license")}>
              <ListItemIcon>
                <VerifiedUser sx={{ color: activeMenu === "/unused-license" ? "var(--active-color)" : "var(--text-color)" }} />
              </ListItemIcon>
              <ListItemText primary="Unused License" sx={{ color: activeMenu === "/unused-license" ? "var(--active-color)" : "var(--text-color)" }} />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton disableRipple onClick={() => handleActiveMenu("/plan")}>
          <ListItemIcon>
            <Inventory sx={{ color: activeMenu === "/plan" ? "var(--active-color)" : "var(--text-color)" }} />
          </ListItemIcon>
          <ListItemText primary="Plan" sx={{ color: activeMenu === "/plan" ? "var(--active-color)" : "var(--text-color)" }} />
        </ListItemButton>

        <ListItemButton disableRipple onClick={() => handleActiveMenu("/force-update")}>
          <ListItemIcon>
            <Update sx={{ color: activeMenu === "/force-update" ? "var(--active-color)" : "var(--text-color)" }} />
          </ListItemIcon>
          <ListItemText primary="Force Update" sx={{ color: activeMenu === "/force-update" ? "var(--active-color)" : "var(--text-color)" }} />
        </ListItemButton>

        <ListItemButton
          disableRipple
          onClick={() => {
            handleBackendDropdown();
          }}
        >
          <ListItemIcon>
            <Storage sx={{ color: "var(--text-color)" }} />
          </ListItemIcon>
          <ListItemText primary="Backend" sx={{ color: "var(--text-color)" }} />
          {openBackendDropdown ? <ExpandLess sx={{ color: "var(--text-color)" }} /> : <ExpandMore sx={{ color: "var(--text-color)" }} />}
        </ListItemButton>

        <Collapse in={openBackendDropdown} timeout="auto" unmountOnExit>
          <List component="nav">
            <ListItemButton disableRipple onClick={() => handleActiveMenu("/backend-force-update")}>
              <ListItemIcon>
                <Storage sx={{ color: activeMenu === "/backend-force-update" ? "var(--active-color)" : "var(--text-color)" }} />
              </ListItemIcon>
              <ListItemText primary="Backend Update" sx={{ color: activeMenu === "/backend-force-update" ? "var(--active-color)" : "var(--text-color)" }} />
            </ListItemButton>
            <ListItemButton disableRipple onClick={() => handleActiveMenu("/backend-result")}>
              <ListItemIcon>
                <Storage sx={{ color: activeMenu === "/backend-result" ? "var(--active-color)" : "var(--text-color)" }} />
              </ListItemIcon>
              <ListItemText primary="Backend Result" sx={{ color: activeMenu === "/backend-result" ? "var(--active-color)" : "var(--text-color)" }} />
            </ListItemButton>
          </List>
        </Collapse>


        <ListItemButton disableRipple onClick={() => handleActiveMenu("/help-center")}>
          <ListItemIcon>
            <SupportAgent sx={{ color: activeMenu === "/help-center" ? "var(--active-color)" : "var(--text-color)" }} />
          </ListItemIcon>
          <ListItemText primary="Help Center" sx={{ color: activeMenu === "/help-center" ? "var(--active-color)" : "var(--text-color)" }} />
        </ListItemButton>

        <ListItemButton
          disableRipple
          onClick={() => {
            handleUploadDropdown();
          }}
        >
          <ListItemIcon>
            <UploadFile sx={{ color: "var(--text-color)" }} />
          </ListItemIcon>
          <ListItemText primary="Upload" sx={{ color: "var(--text-color)" }} />
          {openUploadDropdown ? <ExpandLess sx={{ color: "var(--text-color)" }} /> : <ExpandMore sx={{ color: "var(--text-color)" }} />}
        </ListItemButton>

        <Collapse in={openUploadDropdown} timeout="auto" unmountOnExit>
          <List component="nav">
            <ListItemButton disableRipple onClick={() => handleActiveMenu("/file-upload")}>
              <ListItemIcon>
                <UploadFile sx={{ color: activeMenu === "/file-upload" ? "var(--active-color)" : "var(--text-color)" }} />
              </ListItemIcon>
              <ListItemText primary="Upload File" sx={{ color: activeMenu === "/file-upload" ? "var(--active-color)" : "var(--text-color)" }} />
            </ListItemButton>

            <ListItemButton disableRipple onClick={() => handleActiveMenu("/upload-file-list")}>
              <ListItemIcon>
                <UploadFile sx={{ color: activeMenu === "/upload-file-list" ? "var(--active-color)" : "var(--text-color)" }} />
              </ListItemIcon>
              <ListItemText primary="Upload File List" sx={{ color: activeMenu === "/upload-file-list" ? "var(--active-color)" : "var(--text-color)" }} />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton
          onClick={handleLogout}
          disableRipple
          sx={{
            "&:active": {
              "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: "var(--active-color)",
              },
              "& .MuiSvgIcon-root": {
                color: "var(--active-color)",
              },
            },
          }}
        >
          <ListItemIcon>
            <Logout sx={{ color: "var(--text-color)" }} />
          </ListItemIcon>
          <ListItemText primary="Logout" sx={{ color: "var(--text-color)" }} />
        </ListItemButton>
      </Box>
    </List>
  );
};

export default memo(Sidebar);
