import { HighlightOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateForceUpdateList } from "redux/features/forceUpdate/forceUpdateSlice";
import { updateForceUpdate } from "server/forceUpdate/forceUpdate";
import * as yup from "yup";

const validationSchema = yup.object({
  type: yup.string("Enter your type").required("Type is required"),
  version: yup.string("Enter your version").required("Version is required"),
  force: yup.boolean(),
});

const UpdateForceUpdateModal = memo(({ updateForceUpdateModalOpen, setUpdateForceUpdateModalOpen, currentForceUpdate }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      type: currentForceUpdate?.type || "",
      version: currentForceUpdate?.version || "",
      force: currentForceUpdate?.force || false,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const data = {
          id: currentForceUpdate?._id,
          type: values.type,
          version: values.version,
          force: values.force,
        };

        const result = await updateForceUpdate(data);
        if (result?.data?.success) {
          dispatch(updateForceUpdateList(result?.data?.data));
          enqueueSnackbar(result?.data?.message, { variant: "success" });
          setUpdateForceUpdateModalOpen(false);
          formik.resetForm();
        } else {
          enqueueSnackbar(result?.data?.message, { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("An error occurred while update force update!", { variant: "error" });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal open={updateForceUpdateModalOpen} onClose={() => setUpdateForceUpdateModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          backgroundColor: theme.palette.background.main,
          borderRadius: "5px",
          padding: "25px",
          outline: "none",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={4} columnSpacing={3} alignItems={"center"}>
            <Grid item xs={6}>
              <Typography sx={{ color: theme.palette.text.white, fontWeight: 500 }} className="!capitalize">
                Force Update
              </Typography>
            </Grid>
            <Grid item xs={6} className="text-end">
              <IconButton
                onClick={() => {
                  setUpdateForceUpdateModalOpen(false);
                  formik.resetForm();
                }}
                title="Close"
              >
                <HighlightOff />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Box className="space-y-6">
                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                    },
                  }}
                  size="small"
                >
                  <InputLabel id="type">Type</InputLabel>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        style: {
                          background: theme.palette.background.main,
                        },
                      },
                    }}
                    labelId="type"
                    name="type"
                    id="type"
                    label="Type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                  >
                    <MenuItem value="" disabled key={"first"}>
                      Select type
                    </MenuItem>
                    <MenuItem value="android">Android</MenuItem>
                    <MenuItem value="windows">Windows</MenuItem>
                    <MenuItem value="macos">macOS</MenuItem>
                    <MenuItem value="ios">iOS</MenuItem>
                  </Select>
                  {formik.touched.type && formik.errors.type && <Typography className="!text-[#f44336] !text-[12px] !mt-1">{formik.touched.type && formik.errors.type}</Typography>}
                </FormControl>

                <FormControl
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid var(--primary-color)",
                      },
                      "&:hover fieldset": {
                        border: "1px solid var(--secondary-color)",
                      },
                      "&.Mui-focused fieldset": {
                        border: `1px solid var(--secondary-color)`,
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: theme.palette.text.white,
                      "&.Mui-focused": {
                        color: theme.palette.text.white,
                      },
                    },
                  }}
                >
                  <TextField
                    label="Version"
                    type="text"
                    id="version"
                    name="version"
                    size="small"
                    placeholder="Your version"
                    value={formik.values.version}
                    onChange={formik.handleChange}
                    error={formik.touched.version && Boolean(formik.errors.version)}
                    helperText={formik.touched.version && formik.errors.version}
                  />
                </FormControl>

                <FormControl>
                  <FormControlLabel
                    sx={{
                      color: theme.palette.text.white,
                      "& .MuiFormControlLabel-label": {
                        fontSize: "15px",
                      },
                    }}
                    control={
                      <Checkbox
                        checked={formik.values.force}
                        onChange={formik.handleChange}
                        name="force"
                        sx={{
                          color: "var(--primary-color)",
                          "&.Mui-checked": {
                            color: "var(--secondary-color)",
                          },
                        }}
                      />
                    }
                    label="Force Update"
                  />
                  {formik.touched.force && formik.errors.force && <Typography className="!text-[#f44336] !text-[12px] !mt-1">{formik.errors.force}</Typography>}
                </FormControl>

                <Box className="flex justify-end">
                  <Button
                    sx={{
                      background: "linear-gradient(45deg, #886EEC 30%, #6553D8 90%)",
                      boxShadow: "0 3px 5px 2px rgba(101, 83, 216, 0.3)",
                      color: "white",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      width: "100px",
                      pointerEvents: loading ? "none" : "auto",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : "Update"}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
});

export default UpdateForceUpdateModal;
