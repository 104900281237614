import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    forceUpdateList: [],
}

export const forceUpdateSlice = createSlice({
    name: 'force',
    initialState,
    reducers: {
        setForceUpdateList: (state, action) => {
            state.forceUpdateList = action.payload
        },
        addForceUpdateList: (state, action) => {
            state.forceUpdateList.push(action.payload)
        },
        updateForceUpdateList: (state, action) => {
            const index = state.forceUpdateList.findIndex(force => force._id === action.payload._id)
            if (index !== -1) {
                state.forceUpdateList[index] = {
                    ...state.forceUpdateList[index],
                    ...action.payload,
                }
            }
        },
        removeForceUpdateList: (state, action) => {
            state.forceUpdateList = state.forceUpdateList.filter(force => force._id !== action.payload)
        },
    },
})

export const { setForceUpdateList, removeForceUpdateList, addForceUpdateList, updateForceUpdateList } = forceUpdateSlice.actions

export default forceUpdateSlice.reducer