import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../redux/features/counter/counterSlice";
import authReducer from "./features/auth/authSlice";
import brokerReducer from "./features/broker/brokerSlice";
import drawerReducer from "./features/drawer/drawerSlice";
import forceUpdateReducer from "./features/forceUpdate/forceUpdateSlice";
import licenseReducer from "./features/license/licenseSlice";
import loaderReducer from "./features/loader/loaderSlice";
import planReducer from "./features/plan/planSlice";
import themeReducer from "./features/theme/themeSlice";
import uploadReducer from "./features/upload/uploadSlice";
import helpReducer from "./features/help/helpSlice";
import backendUpdateReducer from "./features/backendUpdate/backendUpdateSlice";
import backendResultReducer from "./features/backendResult/backendResultSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    drawer: drawerReducer,
    themeSlice: themeReducer,
    license: licenseReducer,
    loader: loaderReducer,
    broker: brokerReducer,
    upload: uploadReducer,
    auth: authReducer,
    plan: planReducer,
    force: forceUpdateReducer,
    help: helpReducer,
    backendUpdate: backendUpdateReducer,
    backendResult: backendResultReducer,
  },
});
