import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signin: (state, action) => {
      const json_user = JSON.stringify(action.payload);
      state.user = action.payload;
      localStorage.setItem("user", json_user);
    },
    logOut: (state) => {
      localStorage.removeItem("user");
      localStorage.removeItem("auth-token");
      localStorage.removeItem("refresh-token");
      state.user = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { signin, logOut } = authSlice.actions;

export default authSlice.reducer;
